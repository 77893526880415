/* eslint-disable camelcase */
import { UNIT_SYSTEM } from '../../../shared/config/hydraulics_constants';

const columns = (RenderJsonField, renderActions, renderValueCell, hydraulicsUnit, currentUnit, renderSensitivityAction) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 390,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'run_details',
    headerName: 'Details',
    headerAlign: 'center',
    align: 'center',
    width: 300,
    renderCell: (data) => {
      return <RenderJsonField value={data?.row ? data?.row : {}} />;
    }
  },
  {
    field: 'notes',
    headerName: 'Notes',
    width: 390,
    headerAlign: 'center',
    renderEditCell: renderValueCell,
    editable: true,
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 300,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
  {
    field: 'bh_esd',
    headerName: `ESD (${hydraulicsUnit?.run_response?.summary?.bh_esd?.label})`,
    width: 120,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => {
      if (params?.row?.run_response?.summary?.bh_esd) {
        const value = params?.row?.run_response?.summary?.bh_esd;
        return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(hydraulicsUnit?.run_response?.summary?.bh_esd?.decimals);
      }
      return '';
    },
    cellClassName: 'cellLink'
  },
  {
    field: 'bh_ecd',
    headerName: `ECD (${hydraulicsUnit?.run_response?.summary?.bh_ecd?.label})`,
    width: 160,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => {
      if (params?.row?.run_response?.summary?.bh_ecd) {
        const value = params?.row?.run_response?.summary?.bh_ecd;
        return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(hydraulicsUnit?.run_response?.summary?.bh_ecd?.decimals);
      }
      return '';
    },
    cellClassName: 'cellLink'
  },
  {
    field: 'connection_sbp',
    headerName: `Static SBP (${hydraulicsUnit?.run_response?.summary?.connection_sbp?.label})`,
    width: 180,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => {
      if (params?.row?.run_response?.summary?.connection_sbp) {
        const value = params?.row?.run_response?.summary?.connection_sbp;
        return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(hydraulicsUnit?.run_response?.summary?.connection_sbp?.decimals);
      }
      return '';
    },
    cellClassName: 'cellLink'
  },
  {
    field: 'total_strokes',
    headerName: 'Total Strokes (stk)',
    width: 200,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => params?.row?.displacement_details?.results?.[0]?.strokes,
    cellClassName: 'cellLink'
  },
  {
    field: 'sensitivity',
    headerName: 'Sensitivity',
    width: 200,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderSensitivityAction,
  }
];
export default columns;
