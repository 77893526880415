export const COLORS = {
  LIGHT_RED: '#FF6666',
  RED: '#FF1A1A',
  BRIGHT_RED: '#EA4335',
  LIGHT_BLUE: '#99C5FF',
  BLUE: '#1A7DFF',
  GRAY: '#808080',
  BLACK: '#000000',
  ROYAL_BLUE: '#4285F4',
  GREEN: '#34A853',
  YELLOW: '#FBBC05',
  CINNABAR: '#EA4335',
  LIGHT_GREY: '#D3D3D3',
  WHITE: '#FFFFFF'
};
