import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  data: [],
  errorMessage: '',
  paginatedElements: 0,
};

const mpdCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.MPDCUSTOMERS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      };
    case REDUX_ACTIONS.MPDCUSTOMERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: ''
      };
    case REDUX_ACTIONS.MPDCUSTOMERS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: []
      };
    default:
      return state;
  }
};

export default mpdCustomerReducer;
