import { COLORS } from './colors';

export const HYDRAULICS_CHART_COLORS = {
  ESD: COLORS.LIGHT_RED,
  ESD_WITH_SBP: COLORS.RED,
  ECD: COLORS.LIGHT_BLUE,
  ECD_WITH_SBP: COLORS.BLUE,
  FRACTURE_PRESSURE: COLORS.GRAY,
  PORE_PRESSURE: COLORS.BLACK,
  DISPLACEMENT_SBP: COLORS.LIGHT_RED,
  DISPLACEMENT_STATIC_SBP: COLORS.RED,
  DISPLACEMENT_FLOW_RATE: COLORS.LIGHT_BLUE,
};

export const UNIT_SYSTEM = {
  US: 'US',
  SI: 'SI'
};
