import React, { useEffect, useState } from 'react';
import { Box, Dialog, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { CancelPresentationRounded, ChevronLeft, ChevronRight } from '@mui/icons-material';
import CanvasJSReact from '../../../ui/canvasjs/canvasjs.react';
import PropTypes from 'prop-types';
import styles from './styles';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { HYDRAULICS_CHART_COLORS } from '../../../../shared/config/hydraulics_constants';
import { getOptimalInterval } from '../../../../utils/getOptimalInterval';
import { useSelector } from 'react-redux';
import Datagrid from '../../Datagrid';
import columns from './table_config';
import { renderCell } from '../../Datagrid/table_config';
import useUnitConversion from '../../../../hooks/useUnitConversion';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function SensitivityAnalysisRunChartDialog({ onCloseClick, isOpen, data, wellDetails, rowData }) {
  const classes = styles();
  const chartData = [...data.row.depth_points];
  const window = useSelector((state) => state.wellsWindow);
  const hydraulics = useSelector((state) => state.hydraulics);
  const [selectionMenu, setSelectionMenu] = useState([]);
  const [charttable, setCharttable] = useState('');
  const [tableRecord, setTableRecord] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [runDetails, setRundetails] = useState([]);
  const { unit } = useUnitConversion();
  const depthPoints = hydraulics?.units?.run_response?.depth_points;
  const [chartConfig, setChartConfig] = useState([
    {
      name: 'ESD',
      key: 'esd',
      color: HYDRAULICS_CHART_COLORS.ESD,
      hide: false,
      tooltip: `MD: {y} ${depthPoints?.md?.label} <br/> ESD: {x} ${depthPoints?.esd?.label}`,
      columnFields: [{
        field: 'esd',
        headerName: `ESD (${depthPoints?.esd?.label})`,
        width: 120,
        headerAlign: 'center',
        align: 'center',
        valueFormatter: (params) => {
          if (params.value === null || params.value === undefined || params.value === '') {
            return '';
          }
          const value = Number(params.value);
          return isNaN(value) ? params.value : value.toFixed(depthPoints?.esd?.decimals);
        },
      }]
    },
    {
      name: 'ESD with SBP',
      key: 'esd_with_sbp',
      color: HYDRAULICS_CHART_COLORS.ESD_WITH_SBP,
      hide: false,
      tooltip: `MD: {y} ${depthPoints?.md?.label} <br/> ESD: {x} ${depthPoints?.esd_with_sbp?.label}`,
      columnFields: [
        {
          field: 'esd_with_sbp',
          headerName: `ESD (${depthPoints?.esd_with_sbp?.label})`,
          width: 120,
          headerAlign: 'center',
          align: 'center',
          valueFormatter: (params) => {
            if (params.value === null || params.value === undefined || params.value === '') {
              return '';
            }
            const value = Number(params.value);
            return isNaN(value) ? params.value : value.toFixed(depthPoints?.esd_with_sbp.decimals);
          },
        }]
    },
    {
      name: 'ECD',
      key: 'ecd',
      color: HYDRAULICS_CHART_COLORS.ECD,
      hide: false,
      tooltip: `MD: {y} ${depthPoints?.md?.label} <br/> ECD: {x} ${depthPoints?.ecd?.label} <br/> Annular Pressure: {ann_p} ${depthPoints?.ann_p?.label} <br/> Static Well SBP: {static_sbp} ${depthPoints?.static_sbp?.label}`,
      columnFields: [
        {
          field: 'ecd',
          headerName: `ECD (${depthPoints?.ecd?.label})`,
          width: 120,
          headerAlign: 'center',
          align: 'center',
          valueFormatter: (params) => {
            if (params.value === null || params.value === undefined || params.value === '') {
              return '';
            }
            const value = Number(params.value);
            return isNaN(value) ? params.value : value.toFixed(depthPoints?.ecd?.decimals);
          },
        },
        {
          field: 'ann_p',
          headerName: `Annular Pressure (${depthPoints?.ann_p?.label})`,
          width: 160,
          headerAlign: 'center',
          align: 'center',
        },
        {
          field: 'static_sbp',
          headerName: `Static Well SBP (${depthPoints?.static_sbp?.label})`,
          width: 160,
          headerAlign: 'center',
          align: 'center',
        }
      ],
    },
    {
      name: 'ECD with SBP',
      key: 'ecd_with_sbp',
      color: HYDRAULICS_CHART_COLORS.ECD_WITH_SBP,
      hide: false,
      tooltip: `MD: {y} ${depthPoints?.md?.label} <br/> ECD: {x} ${depthPoints?.ecd?.label} <br/> Annular Pressure: {ann_p_with_sbp} ${depthPoints?.ann_p_with_sbp?.label} <br/> Static Well SBP: {static_sbp_with_sbp} ${depthPoints?.static_sbp_with_sbp?.label}`,
      columnFields: [
        {
          field: 'ecd_with_sbp',
          headerName: `ECD (${depthPoints?.ecd_with_sbp?.label})`,
          width: 120,
          headerAlign: 'center',
          align: 'center',
          valueFormatter: (params) => {
            if (params.value === null || params.value === undefined || params.value === '') {
              return '';
            }
            const value = Number(params.value);
            return isNaN(value) ? params.value : value.toFixed(depthPoints?.ecd_with_sbp?.decimals);
          },
        },
        {
          field: 'ann_p_with_sbp',
          headerName: `Annular Pressure (${depthPoints?.ann_p_with_sbp?.label})`,
          width: 160,
          headerAlign: 'center',
          align: 'center',
        },
        {
          field: 'static_sbp_with_sbp',
          headerName: `Static Well SBP (${depthPoints?.static_sbp_with_sbp?.label})`,
          width: 160,
          headerAlign: 'center',
          align: 'center',
        }
      ],
    },
    {
      name: 'PP',
      key: 'pp',
      color: HYDRAULICS_CHART_COLORS.PORE_PRESSURE,
      hide: false,
      tooltip: `MD: {y} ${window?.units?.md?.label} <br/> PP: {x} ${window?.units?.pp?.label}`
    },
    {
      name: 'FP',
      key: 'fp',
      color: HYDRAULICS_CHART_COLORS.FRACTURE_PRESSURE,
      hide: false,
      tooltip: `MD: {y} ${window?.units?.md?.label} <br/> FP: {x} ${window?.units?.fp?.label}`
    },
  ]);

  function generateRandom() {
    const length = 24;
    const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const generateWindowDataPoints = (config) => {
    const dataPoints = [];

    if (window?.data.length === 1) {
      const singleDataPoint = window?.data[0];
      dataPoints.push(
        { x: singleDataPoint[config.key], y: 0, markerType: 'none' },
        { x: singleDataPoint[config.key], y: singleDataPoint.md },
        { x: singleDataPoint[config.key], y: chartData.at(-1).md, markerType: 'none' }
      );
    } else if (window?.data.length > 1) {
      const firstPoint = window?.data[0];
      const lastPoint = window?.data.at(-1);

      if (firstPoint.md > 0) {
        dataPoints.push({ x: firstPoint[config.key], y: 0, markerType: 'none' });
      }

      window?.data.forEach(item => {
        dataPoints.push({ x: item[config.key], y: item.md });
      });

      dataPoints.push({ x: lastPoint[config.key], y: chartData.at(-1).md, markerType: 'none' });
    }
    const formattedData = dataPoints.map(item => {
      const formattedItem = {
        x: parseFloat(Number(item.x).toFixed(window?.units?.md?.decimals)),
        y: parseFloat(Number(item.y).toFixed(window?.units?.pp?.decimals)),
      };
      if (item.markerType) {
        formattedItem.markerType = item.markerType;
      }
      return formattedItem;
    });
    return formattedData;
  };

  const generateViewportMinMax = () => {
    const minMaxData = [...chartData, ...window.data];
    const keysToCheck = ['esd', 'ecd', 'esd_with_sbp', 'ecd_with_sbp', 'pp', 'fp'];

    const allValues = [];

    // Collect all values from the specified keys
    keysToCheck.forEach(key => {
      minMaxData.forEach(obj => {
        if (key in obj) {
          allValues.push(obj[key]);
        }
      });
    });

    // Calculate the overall lowest and highest values
    const overallLowest = Math.min(...allValues);
    const overallHighest = Math.max(...allValues);

    const results = {
      lowest: overallLowest,
      highest: overallHighest,
    };

    return results;
  };

  const checkEquality = (objects) => {
    return {
      ecdEqual: objects.every(obj => obj.ecd === obj.ecd_with_sbp),
      esdEqual: objects.every(obj => obj.esd === obj.esd_with_sbp),
    };
  };

  useEffect(() => {
    const { ecdEqual, esdEqual } = checkEquality(chartData);
    const hasWindowData = window?.data?.length > 0;

    const updatedChartConfig = chartConfig.filter(item => {
      const removeLegend =
        (item.key === 'ecd_with_sbp' && ecdEqual) ||
        (item.key === 'esd_with_sbp' && esdEqual);
      const shouldRemoveWindowKeys = !hasWindowData && (item.key === 'pp' || item.key === 'fp');
      return !(removeLegend || shouldRemoveWindowKeys);
    });

    const baseColumnFields = [
      {
        field: 'md',
        headerName: `MD (${depthPoints?.md?.label})`,
        headerAlign: 'center',
        align: 'center',
      }
    ];

    const chartConfigData = updatedChartConfig.map(item => ({
      ...item,
      columnFields: [...baseColumnFields, ...(item.columnFields || [])]
    }));

    const filteredData = chartConfigData.filter(item => !['fp', 'pp'].includes(item.key));
    setSelectionMenu(filteredData);

    const charttableItem = filteredData.find(item => item.key === 'ecd_with_sbp') ||
      filteredData.find(item => item.key === 'ecd') ||
      filteredData[0] || '';

    setCharttable(charttableItem);
    setChartConfig(chartConfigData);
  }, []);

  useEffect(() => {
    if (rowData?.row?.run_details) {
      const { drillStrings = [], drillFluids = [] } = hydraulics?.drillDetails?.hydraulicsDrillDetails || {};
      const drillString = drillStrings.find(item => item._id === rowData?.row?.run_details.drillStringsId) || drillStrings[0];
      const drillFluid = drillFluids.find(item => item._id === rowData?.row?.run_details.drillFluidsId) || drillFluids[0];
      const runDetails = {
        ...rowData?.row?.run_details,
        _id: generateRandom(),
        rop: data?.row?.inputs?.rop,
        flow_in: data?.row?.inputs?.flow_in,
        sbp: data?.row?.sbp,
        rpm: data?.row?.inputs?.rpm ?? 0.0,
        drillStringsId: drillString
          ? `${drillString.name.trim()} @ ${drillString.MD.toFixed(hydraulics?.drillDetails?.units?.MD?.decimals)}${depthPoints?.md?.label}`
          : '',
        drillFluidsId: drillFluid
          ? `${drillFluid.name.trim()} @ ${drillFluid.MD.toFixed(hydraulics?.drillDetails?.units?.MD?.decimals)}${depthPoints?.md?.label}`
          : ''
      };
      setRundetails([runDetails]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (charttable) {
      getTableDatas(charttable);
    }
  }, [charttable]);

  const extractValues = (dataArray, config) => {
    return dataArray.map(item => {
      const extracted = config?.columnFields?.reduce((acc, column) => {
        const field = column.field;
        if (item[field] !== undefined) {
          acc[field] = item[field];
        }
        return acc;
      }, {});
      extracted._id = Math.random().toString(36).substring(2, 10);
      return extracted;
    });
  };

  const getTableDatas = (charttable) => {
    const extractedValues = extractValues(chartData, charttable);
    setTableRecord(extractedValues);
  };

  const CustomLegend = () => {
    const updateHide = (key, hideValue) => {
      setChartConfig(prevItems =>
        prevItems.map(item =>
          item.key === key ? { ...item, hide: hideValue } : item
        )
      );
    };

    return (
      <Grid container>
        {chartConfig.map((item) => (
          <Box
            key={item.name}
            className={classes.legendItem}
            sx={{ opacity: item.hide ? 0.4 : 1 }}
            onClick={() => {
              updateHide(item.key, !item.hide);
            }}
          >
            <LinearScaleIcon fontSize='large' sx={{ color: item.color, mr: 1 }} />
            {item.name}
          </Box>
        ))}
      </Grid>
    );
  };

  const options = {
    theme: 'light2',
    animationEnabled: true,
    zoomEnabled: true,
    exportEnabled: true,
    zoomType: 'y',
    toolbar: {
      itemBackgroundColor: '#d3d3d3',
      itemBackgroundColorOnHover: '#3e3e3e',
      buttonBorderColor: '#3e3e3e',
    },
    backgroundColor: '#FFF',
    axisX: {
      viewportMinimum: generateViewportMinMax()?.lowest - 0.1,
      viewportMaximum: generateViewportMinMax()?.highest + 0.1,
      interval: getOptimalInterval(generateViewportMinMax()?.lowest, generateViewportMinMax()?.highest, 10),
      valueFormatString: '0.0',
      labelFontSize: 16,
      gridThickness: 1,
      title: `EMW (${depthPoints?.esd?.label})`,
      titleFontSize: 22,
    },
    axisY: {
      viewportMinimum: null,
      viewportMaximum: null,
      title: `MD (${depthPoints?.md?.label})`,
      titleFontSize: 22,
      labelFontSize: 16,
      includeZero: false,
      valueFormatString: '0',
      reversed: true,
      labelPlacement: 'outside',
      gridThickness: 1,
    },
    height: 800,
    legend: {
      fontSize: 20
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseClick}
      maxWidth={'xl'}
      fullWidth={true}
      style={{
        marginRight: '20px'
      }}
      PaperProps={{
        sx: {
          overflow: 'visible'
        },
      }}
    >
      <div className={classes.dialogTitle}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              {`${wellDetails?.Rig} | ${wellDetails?.Well} | ${rowData.row?.name} | Run ${data?.row?.runNumber} `}
            </Grid>
            <Grid item>
              <IconButton
                sx={{ mr: 2 }}
                onClick={() => {
                  onCloseClick();
                }}
              >
                <CancelPresentationRounded fontSize='large' />
              </IconButton>
            </Grid>
          </Grid>
        </Typography>
      </div>
      <Divider />
      <Grid container item xs={showTable ? 8 : 12} marginTop={'17px'} alignItems="center" justifyContent="center">
        <Grid item>
          <CustomLegend />
        </Grid>
      </Grid>
      <Box style={{
        overflow: 'auto',
        marginTop: '-1.4%'
      }}>
        <Grid container>
          <Grid item xs={showTable ? 8 : 12} sx={{
            p: 3,
            pt: 4,
            '.canvasjs-chart-toolbar': {
              top: '-20px !important',
            },
          }}>
            <CanvasJSChart
              options={{
                ...options,
                data: chartConfig.map((config) => {
                  if (config.hide) return {};
                  if (config.key === 'pp' || config.key === 'fp') {
                    return {
                      type: 'line',
                      name: config.name,
                      showInLegend: false,
                      toolTipContent: config.tooltip,
                      dataPoints: generateWindowDataPoints(config),
                      color: config.color,
                    };
                  }
                  return {
                    type: 'line',
                    name: config.name,
                    showInLegend: false,
                    toolTipContent: config.tooltip,
                    dataPoints: chartData.map(item => ({
                      x: item[config.key],
                      y: item.md,
                      ann_p: item?.ann_p,
                      static_sbp: item?.static_sbp,
                      ann_p_with_sbp: item?.ann_p_with_sbp,
                      static_sbp_with_sbp: item?.static_sbp_with_sbp
                    })),
                    color: config.color,
                  };
                }),
              }}
            />
          </Grid>
          {!showTable && <div className={classes.menuTogglerBtn}>
            <IconButton
              onClick={() => {
                setShowTable(!showTable);
              }}>
              <ChevronLeft sx={{ fontSize: '33px' }} />
            </IconButton>
          </div>}
          {showTable && <Grid item xs={4} marginTop={'10px'}>
            <div className={classes.menuTogglerTableBtn}>
              <IconButton
                onClick={() => {
                  setShowTable(!showTable);
                }}>
                <ChevronRight sx={{ fontSize: '33px' }} />
              </IconButton>
            </div>
            <Box display={'flex'} justifyContent={'end'}>
              <Grid item xs={3} display="flex" justifyContent="flex-end" marginRight={'1.25%'}>
                <FormControl
                  fullWidth
                  sx={{ bgcolor: 'transparent', minWidth: '100px', p: 0, m: 0 }}
                >
                  <InputLabel id={'filter-input-label'}>Filter</InputLabel>
                  <Select
                    labelId={'filter-select-id'}
                    id={'filter-id'}
                    value={charttable}
                    label='Filter'
                    onChange={(e) => setCharttable(e.target.value)}
                    sx={{
                      color: charttable.color,
                      fontWeight: 'bolder',
                      fontSize: '18px',
                      background: 'unset'
                    }}
                  >
                    {selectionMenu?.map((item, idItem) => (
                      <MenuItem key={`item_${item?.name}_${idItem}`} value={item} sx={{ color: item?.color, fontSize: '18px' }} style={{ fontWeight: 'bold' }}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Box>
            {tableRecord && <Grid item xs={12}>
              <Datagrid
                fileName={`${wellDetails?.Well?.trim()}-${rowData.row?.name?.trim()}-${charttable?.name}-Run ${data?.row?.runNumber}`}
                data={tableRecord}
                sx={{
                  '.MuiDataGrid-cell': {
                    fontSize: '1rem',
                    padding: '2px',
                  },
                  '.MuiDataGrid-columnHeader': {
                    fontSize: '1.2rem',
                    fontWeight: '900 !important',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    overflow: 'visible',
                    lineHeight: '1.43rem',
                    whiteSpace: 'normal',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    minHeight: '0px'
                  },
                }}
                loading={false}
                columns={charttable?.columnFields}
                disableStickyHeader={true}
                autoHeight={false}
                hideFooterPagination
                hideFooterSelectedRowCount
                height={'770px'}
                getRowHeight={() => 'auto'}
                pageSize={100}
                toolbar={{
                  options: {
                    columns: false,
                    filters: false,
                    density: false,
                    export: {
                      enabled: true,
                    }
                  },
                }}
              />
            </Grid>}
          </Grid>}
          <Grid item xs={12} marginBottom={'15px'}>
            <Datagrid
              data={runDetails}
              sx={{
                '.MuiDataGrid-cell': {
                  fontSize: '1rem',
                  fontWeight: 900
                },
                '.MuiDataGrid-columnHeader': {
                  fontSize: '1.2rem',
                  fontWeight: '900 !important'
                },
                '& .MuiDataGrid-footerContainer': {
                  display: 'none',
                },
              }}
              loading={false}
              getRowId={(row) => row._id}
              columns={columns(renderCell, hydraulics?.units?.run_details, unit)}
              onRowClick={(params, evt) => {
                evt.preventDefault();
                evt.stopPropagation();
              }}
              disableStickyHeader={false}
              autoHeight={false}
              autoheight
              height={'150px'}
              hideFooterPagination
              hideFooterSelectedRowCount
              editMode="cell"
              toolbar={{
                options: {
                  columns: false,
                  filters: false,
                  density: false,
                  export: false
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

SensitivityAnalysisRunChartDialog.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  wellDetails: PropTypes.any,
  rowData: PropTypes.object
};

export default SensitivityAnalysisRunChartDialog;
