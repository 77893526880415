import Tooltip from '@mui/material/Tooltip';
import { USER_TYPE } from '../../utils/constants';

const assetsEditAccessLevels = [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_MPD_ADMIN, USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN];

const columns = (
  showColumn,
  renderCell,
  renderDataCell,
  renderReportsCell,
  renderAssetsCell,
  renderStatusCell,
  renderAlarmsCell,
  renderTruFlowCell,
  renderJobsRigEditCell,
  renderJobsWellEditCell,
  dropdownEditable,
  renderHydraulicCell,
  renderFavoriteEditCell,
  renderActions,
  user,
  renderJobsCustomersCell
) => [
  {
    field: 'Operator',
    headerName: 'Operator',
    width: 200,
    hide: true,
    renderCell,
  },
  {
    field: 'Latitude',
    headerName: 'Latitude',
    width: 100,
    hide: true,
    renderCell,
  },
  {
    field: 'Longitude',
    headerName: 'Longitude',
    width: 100,
    hide: true,
    renderCell,
  },
  {
    field: 'isFavorite',
    headerName: '',
    renderCell: renderFavoriteEditCell,
    headerAlign: 'center',
    width: 80,
    hide: !showColumn,
  },
  {
    field: 'customers',
    headerName: 'Customers',
    renderCell: renderJobsCustomersCell,
    // eslint-disable-next-line
    hide: user.type === USER_TYPE.PREMIUM_ADMIN || user.type === USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN ? false : true,
    headerAlign: 'center',
    width: 100,
    align: 'center',
  },
  {
    field: 'Rig',
    headerName: 'Rigs',
    width: 300,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <div style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {params.value}
        </div>
      </Tooltip>
    ),
    hide: false,
    headerAlign: 'center',
    editable: dropdownEditable,
    renderEditCell: renderJobsRigEditCell,
    align: 'center',
  },
  {
    field: 'Well',
    headerName: 'Wells',
    width: 300,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <div style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {params.value}
        </div>
      </Tooltip>
    ),
    hide: false,
    headerAlign: 'center',
    editable: dropdownEditable,
    renderEditCell: renderJobsWellEditCell,
    align: 'center',
  },
  {
    field: 'assets',
    headerName: 'Assets',
    renderCell: renderAssetsCell,
    headerAlign: 'center',
    // eslint-disable-next-line
    hide: showColumn && assetsEditAccessLevels.includes(user?.type) ? false : true,
    width: 80,
  },
  {
    field: 'data',
    headerName: 'Data',
    renderCell: renderDataCell,
    headerAlign: 'center',
    hide: !showColumn,
    width: 150,
  },
  {
    field: 'report',
    headerName: 'Reports',
    renderCell: renderReportsCell,
    headerAlign: 'center',
    hide: !showColumn,
  },
  {
    field: 'systemStatus',
    headerName: 'Status',
    renderCell: renderStatusCell,
    headerAlign: 'center',
    hide: false,
  },
  {
    field: 'alarm',
    headerName: 'Alarms',
    renderCell: renderAlarmsCell,
    headerAlign: 'center',
    hide: false,
  },
  {
    field: 'hydraulic',
    headerName: 'Hydraulics',
    renderCell: renderHydraulicCell,
    headerAlign: 'center',
    hide: !showColumn,
    width: 100,
    align: 'center',
  },
  {
    field: 'latestTimestamp',
    headerName: 'Last Update',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'truFlow',
    headerName: 'Tru-Flow',
    width: 200,
    renderCell: renderTruFlowCell,
    headerAlign: 'center',
    align: 'center',
    // hide: !showColumn,
    hide: true
  },
  {
    field: 'usage',
    headerName: 'Usage',
    width: 150,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'remainingLife',
    headerName: 'Remaining Life ',
    width: 110,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
    // eslint-disable-next-line
    hide: showColumn && assetsEditAccessLevels.includes(user?.type) ? false : true,
  },
];

export default columns;
