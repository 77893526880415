import { Box, Typography } from '@mui/material';

const columns = (plotFor, units, renderRunCell, renderRunHideCell) => [
  {
    field: 'hide',
    headerName: 'Show',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Show
      </Typography>
      </Box>
    ),
    width: 50,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderRunHideCell
  },
  {
    field: 'runNumber',
    headerName: 'Run #',
    cellClassName: 'cellLink',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Run #
      </Typography>
      </Box>
    ),
    width: 60,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderRunCell
  },
  {
    field: 'rop',
    headerName: 'ROP',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
       ROP
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
      ({units?.inputs?.rop?.label})
      </Typography>
      </Box>
    ),
    width: 55,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'rpm',
    headerName: 'RPM',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
       RPM
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
      ({units?.inputs?.rpm?.label})
      </Typography>
      </Box>
    ),
    width: 55,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'flow_in',
    headerName: 'Flow Rate',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
       Flow Rate
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
      ({units?.inputs?.flow_in?.label})
      </Typography>
      </Box>
    ),
    width: 80,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'sbp',
    headerName: 'SBP',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
       SBP
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
      ({units?.inputs?.sbp?.label})
      </Typography>
      </Box>
    ),
    width: 55,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'casing_shoe',
    headerName: 'Casing Shoe',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Casing Shoe
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        {plotFor} ({units?.depth_points?.ecd?.label})
      </Typography>
      </Box>
    ),
    width: 100,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(units?.depth_points?.esd?.decimals);
    },
  },
  {
    field: 'landing_point',
    headerName: 'Landing Point',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Landing Point
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
       {plotFor} ({units?.depth_points?.ecd?.label})
      </Typography>
      </Box>
    ),
    width: 100,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(units?.depth_points?.esd?.decimals);
    },
  },
  {
    field: 'bottom_hole',
    headerName: 'Bottom Hole',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Bottom Hole
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
      {plotFor} ({units?.depth_points?.ecd?.label})
      </Typography>
      </Box>
    ),
    width: 100,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(units?.depth_points?.esd?.decimals);
    },
  },
  {
    field: 'static_sbp',
    headerName: 'Static SBP',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Static SBP
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
      ({units?.depth_points?.static_sbp?.label})
      </Typography>
      </Box>
    ),
    width: 90,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(units?.depth_points?.static_sbp?.decimals);
    },
  },
];
export default columns;
