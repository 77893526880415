import { makeStyles } from '@mui/styles';

const styles = makeStyles((theme) => {
  return {
    searchBar: {
      backgroundColor: 'transparent',
      [theme.breakpoints.down('sm')]: {
        width: '55vw',
      },
      width: '250px',
      '&.MuiFilledInput-root:hover': {
        backgroundColor: 'transparent',
      },
      '&.MuiInputBase-root :hover': {
        backgroundColor: 'transparent',
      },
      fontSize: 'larger'
    },
    autoCompleteSuggestions: {
      position: 'absolute',
      background: '#FFF',
      color: '#000',
      width: '100%',
      zIndex: 1200,
      boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
    },
    autoCompleteSuggestionItem: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '6px',
      textAlign: 'start',
      borderBottom: '1px solid #CCC',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#F2F2F2'
      }
    },
    autoCompleteSuggestionIcon: {
      marginTop: '4px',
      color: 'grey'
    }
  };
});

export default styles;
