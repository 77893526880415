/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { CancelPresentationRounded } from '@mui/icons-material';
import AutocompleteComponent from '../Autocomplete';
import { Typography } from '@mui/material';
import { isMobile } from '../../../utils/isMobile';

export default function CustomerDialog({ open, handleClose, rowData, handleSave }) {
  const [mpdCustomers, setmpdCustomers] = useState([]);
  const mpdCustomer = useSelector((state) => state.mpdCustomer);

  useEffect(() => {
    const idToNameMap = Object.fromEntries(
      mpdCustomer.data.map((item) => [item._id, item.name])
    );
    const companyIds = rowData?.CompanyId
      ? Array.isArray(rowData.CompanyId)
        ? rowData.CompanyId
        : [rowData.CompanyId]
      : [];
    const customerNames = companyIds
      .map((id) => idToNameMap[id])
      .filter((name) => name !== undefined && name !== null);
    setmpdCustomers(customerNames);
  }, [rowData]);

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth='md'
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography component="span" variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: isMobile() && '15px' }}>{'Customers'}</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={() => ({
            position: 'absolute',
            right: 8,
            top: 8,
          })}
        >
     <CancelPresentationRounded fontSize='large'/>
        </IconButton>
        <DialogContent dividers sx={{ minWidth: '600px' }}>
            <AutocompleteComponent
                title=''
                data={mpdCustomers}
                onChange={(event, values) => setmpdCustomers([...values])}
                options={mpdCustomer.data.map(item => item.name)}
            />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => {
            const nameToIdMap = Object.fromEntries(
              mpdCustomer.data.map((item) => [item.name, item._id])
            );
            const updatedData = { ...rowData };
            updatedData.CompanyId = mpdCustomers.map((name) => nameToIdMap[name] || null);
            handleSave(updatedData);
            handleClose();
          }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
