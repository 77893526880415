import { USER_TYPE } from '../../utils/constants';

const assetsEditAccessLevels = [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_MPD_ADMIN, USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN];

const columns = (
  renderCell,
  renderDataCell,
  renderReportsCell,
  renderAssetsCell,
  renderStatusCell,
  renderAlarmsCell,
  user,
) => [
  {
    field: 'Operator',
    headerName: 'Operator',
    width: 200,
    hide: true,
    renderCell,
  },
  {
    field: 'Latitude',
    headerName: 'Latitude',
    width: 100,
    hide: true,
    renderCell,
  },
  {
    field: 'Longitude',
    headerName: 'Longitude',
    width: 100,
    hide: true,
    renderCell,
  },
  {
    field: 'Rig',
    headerName: 'Rigs',
    width: 300,
    hide: false,
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'Well',
    headerName: 'Wells',
    width: 300,
    align: 'center',
    renderCell,
    hide: false,
    headerAlign: 'center',
  },
  {
    field: 'assets',
    headerName: 'Assets',
    renderCell: renderAssetsCell,
    headerAlign: 'center',
    // eslint-disable-next-line
    hide: false && assetsEditAccessLevels.includes(user?.type) ? false : true,
    width: 80,
  },
  {
    field: 'data',
    headerName: 'Data',
    renderCell: renderDataCell,
    headerAlign: 'center',
    hide: true,
    width: 150,
  },
  {
    field: 'report',
    headerName: 'Reports',
    renderCell: renderReportsCell,
    headerAlign: 'center',
    hide: true,
  },
  {
    field: 'systemStatus',
    headerName: 'Status',
    renderCell: renderStatusCell,
    headerAlign: 'center',
    hide: false,
  },
  {
    field: 'alarm',
    headerName: 'Alarms',
    renderCell: renderAlarmsCell,
    headerAlign: 'center',
    hide: false,
  },
  {
    field: 'latestTimestamp',
    headerName: 'Last Update',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'usage',
    headerName: 'Usage',
    width: 150,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'remainingLife',
    headerName: 'Remaining Life ',
    width: 110,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
];

export default columns;
