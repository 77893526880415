import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';

export const listMPDCustomers = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.MPDCUSTOMERS_LIST_LOADING,
  });

  return fetch
    .post('selectBox/companies/mpdCustomers')
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.MPDCUSTOMERS_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.MPDCUSTOMERS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};
