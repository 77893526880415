import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  saving: false,
  categories: {
    loading: false,
    data: [],
    errorMessage: '',
    paginatedElements: 0,
  },
  searchTerm: '',
  initialData: [],
  listPartsByCompany: [],
  initialPartsByCompanyData: [],
  customerPartsLoading: false,
  redirectToUsers: false,
  partsFavorites: [],
  showFavorites: false
};

const partReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.PARTS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.PART_CATEGORIES_GET_LOADING:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: true,
          errorMessage: '',
        },
      };
    case REDUX_ACTIONS.PARTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.parts],
        initialData: [...action.payload.parts],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
        listPartsByCompany: [...action.payload.parts],
        initialPartsByCompanyData: [...action.payload.parts],
        partsFavorites: action.payload?.parts?.filter(item => item.isFavorite)?.map(item => item._id) || []
      };
    case REDUX_ACTIONS.PARTS_SET_FAVORITES:
      return {
        ...state,
        partsFavorites: [...action.payload],
      };
    case REDUX_ACTIONS.PART_CATEGORIES_GET_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          data: [...action.payload],
          errorMessage: '',
        },
      };
    case REDUX_ACTIONS.PARTS_CUSTOMER_LIST_LOADING:
      return {
        ...state,
        customerPartsLoading: true,
        errorMessage: '',
        listPartsByCompany: []
      };
    case REDUX_ACTIONS.PARTS_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        customerPartsLoading: false,
        listPartsByCompany: [...action.payload.parts],
        initialPartsByCompanyData: [...action.payload.parts]
      };
    case REDUX_ACTIONS.PARTS_CUSTOMER_LIST_ERROR:
      return {
        ...state,
        customerPartsLoading: false,
        errorMessage: action.payload.message,
        listPartsByCompany: [],
      };
    case REDUX_ACTIONS.PART_CATEGORIES_RESET:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          data: [...state.initialData.map(item => item.Category).filter((value, index, self) => self.indexOf(value) === index).sort()],
          errorMessage: '',
        },
      };
    case REDUX_ACTIONS.PARTS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
        redirectToUsers: action.payload?.redirectToUsers
      };
    case REDUX_ACTIONS.PART_CATEGORIES_GET_ERROR:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          errorMessage: action.payload.message,
          data: [],
        },
      };
    case REDUX_ACTIONS.PARTS_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: [],
      };
    case REDUX_ACTIONS.PARTS_LIST_SAVING:
      return {
        ...state,
        saving: true,
      };
    case REDUX_ACTIONS.PARTS_SET_CLASSIFICATION:
      return {
        ...state,
        saving: false,
        data: [
          ...state.data.map((item) =>
            item._id === action.payload.partId
              ? { ...item, Classification: action.payload.classification }
              : { ...item }
          ),
        ],
        initialData: [
          ...state.data.map((item) =>
            item._id === action.payload.partId
              ? { ...item, Classification: action.payload.classification }
              : { ...item }
          ),
        ],
      };
    case REDUX_ACTIONS.PARTS_FILTER_BY_COMPANY:
      return {
        ...state,
        loading: false,
        listPartsByCompany: [...state.initialData.filter(item => item.VendorId === action.payload.companyId)
        ],
      };
    case REDUX_ACTIONS.PARTS_SET_PRICE:
      return {
        ...state,
        saving: false,
        data: [
          ...state.data.map((item) =>
            item._id === action.payload.partId
              ? { ...item, Price: action.payload.price }
              : { ...item }
          ),
        ],
        initialData: [
          ...state.data.map((item) =>
            item._id === action.payload.partId
              ? { ...item, Price: action.payload.price }
              : { ...item }
          ),
        ]
      };
    case REDUX_ACTIONS.PARTS_SET_PRICE_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.PARTS_SEARCH_CHANGE:
      return {
        ...state,
        searchTerm: action.payload.value,
      };
    case REDUX_ACTIONS.PARTS_LIST_FILTER:
      return {
        ...state,
        showFavorites: false,
        data: [
          ...state.initialData
            .filter((item) => {
              const term = state.searchTerm.toUpperCase();
              return [
                item?.PN?.toUpperCase(),
                item?.Name?.toUpperCase(),
                item?.Description?.toUpperCase(),
                item?.Classification?.toString(),
              ].some(field => (field || '').includes(term));
            })
            .sort((a, b) => {
              const getPriority = (item) => [
                (item?.PN?.toUpperCase() || ''),
                (item?.Name?.toUpperCase() || ''),
                (item?.Description?.toUpperCase() || '')
              ].findIndex(field => field.includes(state.searchTerm.toUpperCase())) + 1 || 4;

              return getPriority(a) - getPriority(b);
            })
        ],
        listPartsByCompany: [
          ...state.initialPartsByCompanyData
            .filter((item) => {
              const term = state.searchTerm.toUpperCase();
              return [
                item?.PN?.toUpperCase(),
                item?.Name?.toString(),
                item?.Description?.toUpperCase(),
                item?.Category?.toUpperCase(),
              ].some(field => (field || '').includes(term));
            })
            .sort((a, b) => {
              const getPriority = (item) => [
                (item?.PN?.toUpperCase() || ''),
                (item?.Name?.toString() || ''),
                (item?.Description?.toUpperCase() || ''),
                (item?.Category?.toUpperCase() || '')
              ].findIndex(field => field.includes(state.searchTerm.toUpperCase())) + 1 || 4;

              return getPriority(a) - getPriority(b);
            })
        ],
      };
    case REDUX_ACTIONS.PARTS_LIST_MOBILE_FILTER:
      return {
        ...state,
        showFavorites: false,
        data: action.payload
          ? [
              ...state.initialData.filter((value) => value.Category === action.payload)
            ]
          : [...state.initialData],
      };
    case REDUX_ACTIONS.PARTS_FAVORITES_FILTER:
      return {
        ...state,
        data: !state.showFavorites ? [...state.initialData.filter(item => state.partsFavorites.includes(item._id))] : [...state.initialData],
        listPartsByCompany: !state.showFavorites ? [...state.initialPartsByCompanyData.filter(item => state.partsFavorites.includes(item._id))] : [...state.initialData],
        showFavorites: !state.showFavorites,
      };
    case REDUX_ACTIONS.PARTS_FAVORITES_FILTER_RESET:
      return {
        ...state,
        showFavorites: false,
      };
    case REDUX_ACTIONS.PARTS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default partReducer;
