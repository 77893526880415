/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { REDUX_ACTIONS } from '../../../../../shared/config/constants';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, DateTimePickerTabs, LocalizationProvider } from '@mui/x-date-pickers';
import { CancelPresentationRounded, DirectionsRun } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import styles from './style';
import { getDateInfo } from '../../../../../utils/dateInfo';
import { dataLoading } from '../../../../../store/actions/dataLoading';
import { Button, Fade, Grid, Modal, TextField, Box, Typography } from '@mui/material';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { enqueueSnackbar } from '../../../../../store/actions/snackbar';
import { CALENDER_PROPS } from '../../../../../utils/constants';
import { format, parse, startOfMonth, endOfMonth } from 'date-fns';
import { COLORS } from '../../../../../shared/config/colors';
import Badge from '@mui/material/Badge';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { fetchJobsActuatorUtilization } from '../../../../../store/actions/jobs';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';

const DateRangeModal = () => {
  const { assetData } = useSelector((state) => state);
  const { jobsActuatorUtilization } = useSelector(state => state.jobs);
  const dispatch = useDispatch();
  const classes = styles();
  const assetDataDate = assetData?.data?.at(-1)?.timestamp
    ? assetData?.data?.at(-1)?.timestamp
    : format(new Date(), 'MM/dd/yyyy HH:mm:ss');

  const lastDateInRecord = new Date(format(parse(assetDataDate, 'MM/dd/yyyy HH:mm:ss', new Date()), 'MM/dd/yyyy HH:mm'));

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timeDiff, setTimeDiff] = useState(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [assetDataType] = useState(searchParams.get('assetDataType'));
  const jobId = assetDataType.split('_')[1];
  const [monthStartEnd, setMonthStartEnd] = useState({
    startDate: startOfMonth(new Date(lastDateInRecord)),
    endDate: endOfMonth(new Date(lastDateInRecord))
  });
  const abortControllerRef = useRef(null);

  const ServerDay = (props) => {
    const { day, outsideCurrentMonth, ...other } = props;
    const getBadgeContent = (day) => {
      const dateStr = format(day, 'MM/dd/yyyy');
      const dayData = jobsActuatorUtilization?.data?.find(obj => obj.date === dateStr);

      if (dayData) {
        return dayData?.utilization
          ? (
        <Box className={classes.badge} sx={{ background: COLORS.GREEN }} />
            )
          : (
        <Box className={classes.badge} sx={{ background: COLORS.GRAY }} />
            );
      }

      return null;
    };

    const badgeContent = getBadgeContent(day);

    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={badgeContent}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
  };

  const debouncedFetchJobsActuatorUtilization = debounce((jobId, monthStartEnd) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;
    dispatch(fetchJobsActuatorUtilization(jobId, monthStartEnd, controller.signal));
  }, 500);

  useEffect(() => {
    const month = String(monthStartEnd.endDate.getMonth() + 1).padStart(2, '0');
    const year = monthStartEnd.endDate.getFullYear();
    const keyMonthValue = `${month}/${year}`;
    if (keyMonthValue in jobsActuatorUtilization.dataSortedByMonth) {
      setTimeout(() => {
        dispatch({
          type: REDUX_ACTIONS.JOBS_ACTUATOR_UTILIZATION_SHOW_DATA,
          payload: jobsActuatorUtilization.dataSortedByMonth[keyMonthValue],
        });
      }, 100);
      return;
    }
    if (assetDataType.includes('ActuatorData')) {
      debouncedFetchJobsActuatorUtilization(jobId, monthStartEnd);
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      debouncedFetchJobsActuatorUtilization.cancel();
    };
  }, [monthStartEnd]);

  useEffect(() => {
    const updateStartDate = new Date(lastDateInRecord);
    updateStartDate.setDate(updateStartDate.getDate() - 1);
    setStartDate(updateStartDate);
    setEndDate(lastDateInRecord);
  }, []);

  useEffect(() => {
    const currentStartDate = new Date(startDate);
    const currentEndDate = new Date(endDate);
    if (startDate && endDate) {
      setTimeDiff((currentEndDate.getTime() - currentStartDate.getTime()) / 1000);
    }
  }, [startDate, endDate]);

  const handleClose = () => {
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_TOOGLE_DATES_MODAL,
      payload: false,
    });
  };
  const handleApply = () => {
    if (endDate > lastDateInRecord) {
      dispatch(
        enqueueSnackbar(
          'Please select valid end date',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }
    if (timeDiff > 90000 || startDate > endDate) { // return if time diff is greater than 24 hrs with buffer to DST
      dispatch(
        enqueueSnackbar(
          'Please select valid end date',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }
    const formattedStartDate = getDateInfo(startDate, '/').formattedDayTime;
    const formattedEndDate = getDateInfo(endDate, '/').formattedDayTime;
    dispatch(dataLoading(true));
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
      payload: {
        dateRange: {
          start: formattedStartDate,
          end: formattedEndDate,
        },
      },
    });
    handleClose();
  };
  const handleStartDateUpdate = (date) => {
    setStartDate(date);
  };
  const handleEndDateUpdate = (date) => {
    setEndDate(date);
  };

  const adjustSeconds = (date, type, timeDiff) => {
    const newDate = new Date(date);
    const currentSeconds = newDate.getSeconds();

    const adjustedSeconds = type === 'increase'
      ? currentSeconds + timeDiff
      : currentSeconds - timeDiff;

    newDate.setSeconds(adjustedSeconds);
    return newDate;
  };

  const handleTimeShift = (type) => {
    if (timeDiff > 90000 || startDate > endDate) { // return if time diff is greater than 24 hrs with buffer to DST
      dispatch(
        enqueueSnackbar(
          'Please select valid end date',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }
    const currentStartDate = new Date(startDate);
    const currentEndDate = new Date(endDate);
    setStartDate(adjustSeconds(currentStartDate, type, timeDiff));
    setEndDate(adjustSeconds(currentEndDate, type, timeDiff));
  };

  const handleMonthChange = (month) => {
    setMonthStartEnd({
      startDate: startOfMonth(new Date(month)),
      endDate: endOfMonth(new Date(month))
    });
  };

  const handleStartPickerOpen = () => {
    setMonthStartEnd({
      startDate: startOfMonth(startDate),
      endDate: endOfMonth(startDate)
    });
  };

  const handleEndPickerOpen = () => {
    setMonthStartEnd({
      startDate: startOfMonth(endDate),
      endDate: endOfMonth(endDate)
    });
  };

  const actuatorUtilizationProps = () => {
    if (assetDataType.includes('ActuatorData')) {
      return {
        slots: {
          day: ServerDay,
          tabs: CustomTabs
        },
        onMonthChange: handleMonthChange,
      };
    }
    return {};
  };

  function CustomTabs(props) {
    return (
      <React.Fragment>
        {jobsActuatorUtilization.loading && <LinearProgress sx={{ m: '2px 0' }} />}
        {!jobsActuatorUtilization.loading && jobsActuatorUtilization?.data?.length === 0 && <Typography sx={{ textAlign: 'center' }}>No data</Typography>}
        <DateTimePickerTabs {...props} />
      </React.Fragment>
    );
  }

  return (
    <Modal
      open={assetData.isDateRangeModalOpen}
      onClose={handleClose}
      className={classes.modal}
      closeAfterTransition
    >
      <Fade in={assetData.isDateRangeModalOpen}>
        <div className={classes.paper}>
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            {startDate && endDate && (
            <Box>
            <Button
              onClick={() => handleTimeShift('decrease')}
            >
              <FastRewindIcon fontSize='large' htmlColor ='DimGray'/>
            </Button>
            <Button
                onClick={() => handleTimeShift('increase')}
            >
              <FastForwardIcon fontSize='large' htmlColor ='DimGray'/>
            </Button>
            </Box>
            )}
            <Box className={classes.container}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  {...CALENDER_PROPS}
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateUpdate}
                  onOpen={handleStartPickerOpen}
                  className={classes.dateTimePicker}
                  classes={{
                    switchHeader: classes.datePickerHeader,
                  }}
                  textField={(params) => <TextField {...params} />}
                  showDaysOutsideCurrentMonth={false}
                  {...actuatorUtilizationProps()}
                />
                <DateTimePicker
                  {...CALENDER_PROPS}
                  label="End Date"
                  value={endDate}
                  maxDateTime={lastDateInRecord}
                  onChange={handleEndDateUpdate}
                  onOpen={handleEndPickerOpen}
                  className={classes.dateTimePicker}
                  textField={(params) => <TextField {...params} />}
                  showDaysOutsideCurrentMonth={false}
                  {...actuatorUtilizationProps()}
                />
              </LocalizationProvider>
            </Box>
            <Button
              onClick={handleApply}
              disabled={!startDate || !endDate || startDate >= endDate}
            >
              <DirectionsRun fontSize='large' htmlColor ='DimGray'/>
            </Button>
            <Button
              className={classes.closeModalButton}
              onClick={handleClose}
            >
              <CancelPresentationRounded fontSize='large' htmlColor ='DimGray'/>
            </Button>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};
export default DateRangeModal;
