import { useEffect, useState } from 'react';
import { Search } from '@mui/icons-material';
import { Box, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Typography } from '@mui/material';
import styles from './styles';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from '../../../../utils/isMobile';
const SearchAutoComplete = () => {
  const mobile = isMobile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { searchTerm } = useSelector((state) => state.searchBox);
  const classes = styles();
  const [filter, setFilter] = useState(null);
  const [searchChange, setSearchChange] = useState(null);
  const preferences = useSelector((state) => state.currentUser);
  const parts = useSelector((state) => state.parts);
  const [searchSuggestions, setSearchSuggestions] = useState({
    open: false,
    data: []
  });
  const partsByCompany = preferences.marketPlaceInventoryView === 2;

  function searchArray(array, searchText) {
    const lowerSearchText = searchText.toLowerCase();

    const filteredResults = array.filter(item =>
      item.PN.toLowerCase().includes(lowerSearchText) ||
        item.Name.toLowerCase().includes(lowerSearchText) ||
          item.Description.toLowerCase().includes(lowerSearchText)
    );

    // Sort the filtered results based on the number of matches (higher number of matches can be ranked higher)
    const sortedResults = filteredResults.sort((a, b) => {
      const aPNMatch = a.PN.toLowerCase().includes(lowerSearchText) ? 3 : 0; // 3 = Highest priority
      const bPNMatch = b.PN.toLowerCase().includes(lowerSearchText) ? 3 : 0;

      const aNameMatch = a.Name.toLowerCase().includes(lowerSearchText) ? 2 : 0; // 2 = Medium priority
      const bNameMatch = b.Name.toLowerCase().includes(lowerSearchText) ? 2 : 0;

      const aDescriptionMatch = a.Description.toLowerCase().includes(lowerSearchText) ? 1 : 0; // 1 = Lowest priority
      const bDescriptionMatch = b.Description.toLowerCase().includes(lowerSearchText) ? 1 : 0;

      // Calculate the total priority match score for each item (higher score = higher priority)
      const aMatchScore = aPNMatch + aNameMatch + aDescriptionMatch;
      const bMatchScore = bPNMatch + bNameMatch + bDescriptionMatch;

      return bMatchScore - aMatchScore; // Sort in descending order based on match score
    });

    // Return the top 10 results
    return sortedResults.slice(0, 10);
  }

  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      if (searchTerm) {
        setSearchSuggestions({
          open: true,
          data: searchArray(partsByCompany ? parts?.initialPartsByCompanyData : parts.initialData, searchTerm)
        });
      } else {
        setSearchSuggestions({
          open: false,
          data: []
        });
      }
    }, 500);

    return () => {
      clearTimeout(debouncedSearch);
    };
  }, [searchTerm]);

  const getActionType = () => {
    switch (location.pathname) {
      case '/Shop':
        return {
          filter: REDUX_ACTIONS.PARTS_LIST_FILTER,
          searchChange: REDUX_ACTIONS.PARTS_SEARCH_CHANGE
        };
      // Add more cases as needed
      default:
        return {
          filter: null,
          searchChange: null,
        };
    }
  };

  useEffect(() => {
    const { filter, searchChange } = getActionType();
    setFilter(filter);
    setSearchChange(searchChange);
    // clean search and reload all items on page change
    dispatch({
      type: searchChange,
      payload: { value: '' }
    });
    dispatch({
      type: filter,
    });
    dispatch({
      type: REDUX_ACTIONS.SEARCHBOX_SET_TERMS,
      payload: { searchTerm: '' },
    });
  }, [location.pathname]);

  const handleSearch = (value) => {
    if (mobile && (!searchTerm || searchTerm === '') && location.search) {
      dispatch({
        type: REDUX_ACTIONS.PART_CATEGORIES_RESET,
      });
      navigate(location.pathname, { replace: true });
      return;
    } else if (mobile && (!searchTerm || searchTerm === '') && !location.search) {
      dispatch({
        type: REDUX_ACTIONS.PART_CATEGORIES_RESET,
      });
    }
    dispatch({
      type: searchChange,
      payload: { value }
    });
    dispatch({
      type: filter,
    });
    setTimeout(() => {
      setSearchSuggestions({
        open: false,
        data: []
      });
    }, 600);
  };

  const handleOnChange = (e) => {
    dispatch({
      type: REDUX_ACTIONS.SEARCHBOX_SET_TERMS,
      payload: { searchTerm: e.target.value },
    });
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleSearch(searchTerm);
    }
  };

  const handleOnClickSuggestion = (item) => {
    const suggestionItemText = item?.PN;
    dispatch({
      type: REDUX_ACTIONS.SEARCHBOX_SET_TERMS,
      payload: { searchTerm: suggestionItemText },
    });
    handleSearch(suggestionItemText);
  };

  return (
    <Box sx={{ marginLeft: mobile ? 0 : 'auto', position: 'relative' }}>
      <FormControl fullWidth style={{ marginRight: '5px' }}>
        <InputLabel
          htmlFor="outlined-adornment-search"
          inputprops={{ classes: { root: classes.searchBar } }}
        >
          Search
        </InputLabel>
        <FilledInput
          autoComplete='off'
          className={classes.searchBar}
          id="outlined-adornment-search"
          type="text"
          label="Search"
          value={searchTerm}
          onChange={handleOnChange}
          onKeyDown={handleEnter}
          onFocus={() => {
            if (searchTerm) {
              setSearchSuggestions({
                open: true,
                data: searchArray(partsByCompany ? parts?.initialPartsByCompanyData : parts.initialData, searchTerm)
              });
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={() => handleSearch(searchTerm)}
              >
                <Search/>
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Box className={classes.autoCompleteSuggestions}>
         {searchSuggestions?.data?.map((item) => {
           return (
            <Box
              key={item?._id}
              className={classes.autoCompleteSuggestionItem}
              onClick={() => handleOnClickSuggestion(item)}
              >
              <Typography>{item?.Name} - {item?.PN}</Typography>
              <Box className={classes.autoCompleteSuggestionIcon}>
                <Search fontSize='small' />
              </Box>
             </Box>
           );
         })}
        </Box>
    </Box>
  );
};

export default SearchAutoComplete;
