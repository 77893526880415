import { USER_TYPE } from '../../utils/constants';
import { renderCustomPrice } from '../../utils/renderCustomPrice';
const columns = (renderCell, userType, renderActions, renderDescriptionCell) => [
  {
    field: 'Classification',
    headerName: 'Classification',
    hide: true,
    type: 'number',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: '_id',
    headerName: '_id',
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'isFavorite',
    headerName: 'isFavorite',
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Image',
    headerName: 'Image',
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'PN',
    headerName: 'PN',
    width: 120,
    hide: true,
    renderCell,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Category',
    headerName: 'Category',
    width: 140,
    renderCell,
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Name',
    headerName: 'Name',
    width: 140,
    renderCell,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Size',
    headerName: 'Size',
    width: 120,
    hide: false,
    renderCell,
    type: 'number',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Price',
    headerName: 'Price',
    width: 120,
    hide: false,
    editable: renderActions && (userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || userType === USER_TYPE.SELLER_MARKETPLACE_ADMIN),
    renderCell: renderCustomPrice,
    type: 'number',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'costCode',
    headerName: 'Cost Code',
    width: 150,
    editable: userType === USER_TYPE.CUSTOMER_MANAGER || userType === USER_TYPE.CUSTOMER_ADMIN || userType === USER_TYPE.CUSTOMER_USER,
    hide: !(userType === USER_TYPE.CUSTOMER_MANAGER || userType === USER_TYPE.CUSTOMER_ADMIN || userType === USER_TYPE.CUSTOMER_USER),
    renderCell,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Description',
    headerName: 'Description',
    hide: false,
    // eslint-disable-next-line
    renderCell: userType === USER_TYPE.CUSTOMER_USER || userType === USER_TYPE.CUSTOMER_RIG || userType === USER_TYPE.CUSTOMER_MANAGER || userType === USER_TYPE.CUSTOMER_ADMIN || userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || userType === USER_TYPE.SELLER_MARKETPLACE_ADMIN  ? renderDescriptionCell : renderCell,
    width: 600,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'VendorId',
    headerName: 'Seller',
    hide: true,
    width: 200,
    renderCell,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 300,
    hide: !renderActions,
    headerAlign: 'center',
    renderCell: renderActions,
  },
];

const gridOnlyColumns = (renderCell, userType, renderDescriptionCell) => [
  {
    field: '_id',
    headerName: '_id',
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Image',
    headerName: 'Image',
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'PN',
    headerName: 'PN',
    width: 200,
    hide: false,
    renderCell,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Category',
    headerName: 'Category',
    width: 200,
    renderCell,
    hide: false,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Name',
    headerName: 'Name',
    width: 200,
    renderCell,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'Size',
    headerName: 'Size',
    width: 120,
    hide: false,
    renderCell,
    type: 'number',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Price',
    headerName: 'Price',
    width: 120,
    hide: false,
    renderCell: renderCustomPrice,
    type: 'number',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Description',
    headerName: 'Description',
    hide: false,
    // eslint-disable-next-line
    renderCell: userType === USER_TYPE.CUSTOMER_USER || userType === USER_TYPE.CUSTOMER_MANAGER || userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN || userType === USER_TYPE.SELLER_MARKETPLACE_ADMIN  ? renderDescriptionCell : renderCell,
    width: 600,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Classification',
    headerName: 'Classification',
    hide: false,
    type: 'number',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'VendorId',
    headerName: 'Seller',
    hide: false,
    width: 350,
    renderCell,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'isFavorite',
    headerName: 'isFavorite',
    hide: true,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
];

export { columns, gridOnlyColumns };
