import { USER_TYPE } from '../../utils/constants';
const columns = (renderCell, renderActions, renderEdit, renderWellsNameEditCell, renderValueCell, renderValueNumberCell, renderRigNameEditCell, renderHydraulicCell, renderWellsFavoritesCell, renderWellsCustomersCell, userType) => [
  {
    field: 'favorites',
    headerName: '',
    renderCell: renderWellsFavoritesCell,
    headerAlign: 'center',
    width: 80,
    align: 'center',
  },
  {
    field: 'customers',
    headerName: 'Customers',
    renderCell: renderWellsCustomersCell,
    // eslint-disable-next-line
    hide: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN ? false : true,
    headerAlign: 'center',
    width: 100,
    align: 'center',
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 310,
    editable: true,
    renderEditCell: renderWellsNameEditCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'rig',
    headerName: 'Rig',
    width: 320,
    renderEditCell: renderRigNameEditCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'operator',
    headerName: 'Operator',
    width: 200,
    renderEditCell: renderValueCell,
    headerAlign: 'center',
    editable: true,
    align: 'center'
  },
  {
    field: 'latitude',
    headerName: 'Latitude',
    width: 150,
    renderEditCell: renderValueNumberCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'longitude',
    headerName: 'Longitude',
    width: 150,
    renderEditCell: renderValueNumberCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'surveys',
    headerName: 'Surveys',
    width: 110,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'window',
    headerName: 'Window',
    width: 110,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'geometry',
    headerName: 'Geometry',
    width: 110,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'drill_string',
    headerName: 'Drillstring',
    width: 110,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'drilling_fluid',
    headerName: 'Drilling Fluid',
    width: 110,
    renderCell: renderEdit,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'hydraulic',
    headerName: 'Hydraulics',
    renderCell: renderHydraulicCell,
    headerAlign: 'center',
    width: 100,
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];

export default columns;
