import { ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import { Badge, Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OrdersCartModalDialog from '../../OrdersCartModalDialog';
import QuotesCartModalDialog from '../../QuotesCartModalDialog';
import OrdersCartMobileDialog from '../../../mobile/ui/OrdersCartMobileDialog';
import { isMobile } from '../../../../utils/isMobile';
import QuotesCartMobileDialog from '../../../mobile/ui/QuotesCartMobileDialog';

const ShoppingCart = () => {
  const orders = useSelector((state) => state.orders);
  const quotes = useSelector((state) => state.quotes);
  const { shoppingCarts } = useSelector((state) => state.currentUser.preferences);
  const mobile = isMobile();

  const [isGreenCartDialogOpen, setIsGreenCartDialogOpen] = useState(false);
  const [isGrayCartDialogOpen, setIsGrayCartDialogOpen] = useState(false);
  const [maxOrderAmount, setMaxOrderAmount] = useState(shoppingCarts?.maxOrderAmount);
  const [splitOrder, setSplitOrder] = useState(false);

  useEffect(() => {
    setMaxOrderAmount(shoppingCarts?.maxOrderAmount);
  }, [splitOrder, shoppingCarts]);

  const onOrderClick = () => {
    return null;
    // TODO: Call backend to perform order
  };

  const onQuoteClick = () => {
    return null;
    // TODO: Call backend to perform quote
  };

  return (
    <Box display="flex" alignItems="center" sx={{ width: 'auto', textAlign: 'right' }}>
      {orders?.greenList?.length > 0 && (
        <IconButton
          size="large"
          aria-label="orders"
          color="success"
          onClick={() => {
            setIsGreenCartDialogOpen(true);
            setMaxOrderAmount(shoppingCarts?.maxOrderAmount);
          }}
        >
          <Badge
            badgeContent={orders.greenList.length}
            color="error"
            style={{
              backgroundColor: 'white',
              borderRadius: '25px',
              padding: '5px',
            }}
          >
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      )}
      {quotes?.grayList?.length > 0 && (
        <IconButton
          size="large"
          aria-label="Quotes"
          color="rgba(0, 0, 0, 0.2)"
          onClick={() => setIsGrayCartDialogOpen(true)}
        >
          <Badge
            badgeContent={quotes.grayList.length}
            color="error"
            style={{
              backgroundColor: 'white',
              borderRadius: '25px',
              padding: '5px',
            }}
          >
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      )}

      {mobile
        ? (
      <OrdersCartMobileDialog
        isOpen={isGreenCartDialogOpen}
        partIDs={orders?.greenList || []}
        onCloseClick={() => setIsGreenCartDialogOpen(false)}
        onActionClick={onOrderClick}
        setMaxOrderAmount={setMaxOrderAmount}
        maxOrderAmount={maxOrderAmount}
        splitOrder={splitOrder}
        setSplitOrder={setSplitOrder}
      />
          )
        : (
      <OrdersCartModalDialog
        isOpen={isGreenCartDialogOpen}
        partIDs={orders?.greenList || []}
        onCloseClick={() => {
          setIsGreenCartDialogOpen(false);
          setMaxOrderAmount('');
        }}
        onActionClick={onOrderClick}
        setMaxOrderAmount={setMaxOrderAmount}
        maxOrderAmount={maxOrderAmount}
        splitOrder={splitOrder}
        setSplitOrder={setSplitOrder}
        enableReset={shoppingCarts?.maxOrderAmount}
      />
          )}
      {mobile
        ? (
       <QuotesCartMobileDialog
          isOpen={isGrayCartDialogOpen}
          partIDs={quotes?.grayList || []}
          onCloseClick={() => setIsGrayCartDialogOpen(false)}
          onActionClick={onQuoteClick}
        />
          )
        : (
        <QuotesCartModalDialog
        isOpen={isGrayCartDialogOpen}
        partIDs={quotes?.grayList || []}
        onCloseClick={() => setIsGrayCartDialogOpen(false)}
        onActionClick={onQuoteClick}
      />
          )}
    </Box>
  );
};

export default ShoppingCart;
