/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles';
import { Box, Checkbox, CircularProgress, Dialog, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ArrowDropUp, CancelPresentationRounded, ChevronLeft, ChevronRight, Circle, FilterAltRounded, LinearScaleOutlined, RestoreOutlined, Square } from '@mui/icons-material';
import CanvasJSReact from '../../canvasjs/canvasjs.react';
import Datagrid from '../../Datagrid';
import columns from './table_config';
import { useDispatch, useSelector } from 'react-redux';
import { listSensitivityAnalysisResults } from '../../../../store/actions/hydraulics';
import { HYDRAULICS_CHART_COLORS } from '../../../../shared/config/hydraulics_constants';
import { getOptimalInterval } from '../../../../utils/getOptimalInterval';
import SensitivityAnalysisRunChartDialog from '../SensitivityAnalysisRunDetailsChartDialog';
import { FILTER_STYLE, SENSITIVITY_ANALYSIS_FILTER_LABEL, SENSITIVITY_ANALYSIS_FILTER_PARAMS } from '../../../../utils/constants';
import { COLORS } from '../../../../shared/config/colors';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SensitivityAnalysisChart = ({
  onCloseClick,
  isOpen,
  rowData,
  wellDetails
}) => {
  const classes = styles();
  const [tableRow, setTableRows] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const { sensitivityAnalysis } = useSelector((state) => state.hydraulics);
  const dispatch = useDispatch();
  const [plotFor, setPlotFor] = useState('ECD');
  const window = useSelector((state) => state.wellsWindow);
  const [chartData, setChartData] = useState([]);
  const [showRunChart, setShowRunChart] = useState({
    open: false,
    data: null
  });
  const containerRef = useRef(null);
  const scrollPositionRef = useRef(0);
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [hiddenChartData, setHiddenChartData] = useState([]);

  const handleScroll = () => {
    if (containerRef.current) {
      scrollPositionRef.current = containerRef.current.scrollTop;
    }
  };

  const [chartConfig, setChartConfig] = useState([
    {
      name: 'PP',
      key: 'pp',
      color: HYDRAULICS_CHART_COLORS.PORE_PRESSURE,
      hide: false,
      tooltip: `MD: {y} ${window?.units?.md?.label} <br/> PP: {x} ${window?.units?.pp?.label}`
    },
    {
      name: 'FP',
      key: 'fp',
      color: HYDRAULICS_CHART_COLORS.FRACTURE_PRESSURE,
      hide: false,
      tooltip: `MD: {y} ${window?.units?.md?.label} <br/> FP: {x} ${window?.units?.fp?.label}`
    },
  ]);

  useEffect(() => {
    const body = {
      runId: rowData?.row?._id,
      wellsInfoId: rowData?.row?.wellsInfoId
    };
    dispatch(listSensitivityAnalysisResults(body));
  }, []);

  useEffect(() => {
    if (!sensitivityAnalysis?.data?.length) return;

    const extractedValues = sensitivityAnalysis.data.map(({ inputs }) => ({
      rop: inputs.rop,
      rpm: inputs.rpm,
      sbp: inputs.sbp,
      flow_in: inputs.flow_in
    }));

    const getFilteredValues = (key) => {
      const sortedValues = [...new Set(extractedValues.map(item => item[key]))].sort((a, b) => a - b);
      const len = sortedValues.length;
      return len > 1 ? [sortedValues[0], sortedValues[Math.max(len - 2, 0)], sortedValues[len - 1]] : [sortedValues[0], sortedValues[0], sortedValues[0]];
    };

    const [rop, rpm, flow_in, sbp] = SENSITIVITY_ANALYSIS_FILTER_PARAMS.map(getFilteredValues);

    const isChecked = { rop: true, rpm: true, flow_in: true, sbp: true };

    setFilterData(
      SENSITIVITY_ANALYSIS_FILTER_LABEL.map((label, i) => ({
        label,
        rop: rop[i],
        rpm: rpm[i],
        flow_in: flow_in[i],
        sbp: sbp[i],
        isChecked,
        _id: Math.random().toString(36).substring(2, 10)
      }))
    );
  }, [sensitivityAnalysis]);

  useEffect(() => {
    setShowFilter(!sensitivityAnalysis.loading && !!sensitivityAnalysis.data);
    setShowTable(!sensitivityAnalysis.loading && !!sensitivityAnalysis.data);
  }, [sensitivityAnalysis.loading, sensitivityAnalysis.data]);

  useEffect(() => {
    if (!sensitivityAnalysis?.data?.length && !filterData?.length) return;

    const plotKey = plotFor === 'ECD' ? 'ecd_with_sbp' : 'esd_with_sbp';
    const plotName = plotFor;
    const units = sensitivityAnalysis.units?.depth_points;

    const getStyle = (value, key) => {
      const values = SENSITIVITY_ANALYSIS_FILTER_LABEL.map(label => filterData.find(item => item.label === label)?.[key] ?? 0);

      if (values.every(v => v === 0)) return 'Actual';
      return SENSITIVITY_ANALYSIS_FILTER_LABEL[values.indexOf(value)] || 'Actual';
    };

    const processedData = sensitivityAnalysis.data.map(({ depth_points = [], depths_summary = {}, inputs = {} }, index) => {
      const { rop, rpm, flow_in, sbp } = inputs;

      const styles = {
        rop: getStyle(rop, 'rop'),
        rpm: getStyle(rpm, 'rpm'),
        flow_in: getStyle(flow_in, 'flow_in'),
        sbp: getStyle(sbp, 'sbp'),
      };

      return {
        depth_points,
        depths_summary,
        inputs,
        hide: false,
        name: plotName,
        key: plotKey,
        index,
        runNumber: index + 1,
        type: 'line',
        color: FILTER_STYLE[styles.flow_in]?.color || 'black',
        markerType: FILTER_STYLE[styles.rop]?.markerType || 'circle',
        lineDashStyle: FILTER_STYLE[styles.rpm]?.lineDashStyle || 'solid',
        lineDashType: FILTER_STYLE[styles.rpm]?.lineDashType || 'solid',
        lineThickness: FILTER_STYLE[styles.sbp]?.lineThickness || 1,
        toolTipContent: `Run ${index + 1} <br/> MD: {y} ${units?.md?.label} <br/> ${plotName}: {${plotKey}} ${units?.esd?.label} <br/>`,
        showInLegend: false,
        dataPoints: depth_points.map(({ md, ...item }) => ({
          x: item[plotKey],
          y: md,
          ...item,
        })),
      };
    });

    const records = processedData.map((record, index) => {
      const { depths_summary, inputs } = record;
      return {
        ...record,
        index,
        hide: true,
        runNumber: index + 1,
        casing_shoe: depths_summary.casing_shoe[plotKey],
        landing_point: depths_summary.landing_point[plotKey],
        bottom_hole: depths_summary.hole_depth[plotKey],
        static_sbp: depths_summary.hole_depth.static_sbp_with_sbp,
        _id: Math.random().toString(36).substring(2, 10),
        rop: inputs.rop,
        rpm: inputs.rpm ?? 0.0,
        flow_in: inputs.flow_in,
        sbp: inputs.sbp
      };
    });

    const isAllFalseForAnyParam = SENSITIVITY_ANALYSIS_FILTER_PARAMS.some(param => {
      return filterData.every(item => item.isChecked[param] === false);
    });

    if (isAllFalseForAnyParam) {
      setChartData([]);
      setTableRows([]);
      return;
    }

    const conditions = filterData.reduce((acc, { isChecked, ...criteria }) => {
      Object.entries(isChecked).forEach(([key, checked]) => {
        if (checked) {
          acc[key] = acc[key] || new Set();
          acc[key].add(criteria[key]);
        }
      });
      return acc;
    }, {});

    const isMatch = ({ inputs }) => Object.entries(conditions).every(([key, values]) => values.has(inputs[key]));
    setChartData(processedData.filter(isMatch));
    setHiddenChartData(processedData.filter(isMatch));
    setTableRows(records.filter(isMatch));
  }, [sensitivityAnalysis, plotFor, filterData]);

  const handleResetAll = () => {
    setFilterData((prevData) =>
      prevData.map((item) => ({
        ...item,
        isChecked: Object.fromEntries(
          Object.keys(item.isChecked).map((key) => [key, true])
        ),
      }))
    );
  };

  const columns_table = [
    {
      field: 'label',
      headerName: 'Sensitivity',
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
            Sensitivity
          </Typography>
        </Box>
      ),
      width: 100,
      headerClassName: 'table-header',
      align: 'center',
      headerAlign: 'center',
    },
    ...['rop', 'rpm', 'flow_in', 'sbp'].map((col) => ({
      field: col,
      headerName: col === 'flow_in' ? 'Flow Rate' : col.toUpperCase(),
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
            {col === 'flow_in' ? 'Flow Rate' : col.toUpperCase()}
          </Typography>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
            ({sensitivityAnalysis?.units?.inputs?.[col]?.label || ''})
          </Typography>
        </Box>
      ),
      width: 160,
      headerAlign: 'center',
      headerClassName: 'table-header',
      renderCell: (params) => {
        const row = params.row;
        const isColumnUniform = filterData.every((item) => item[col] === filterData[0][col]);
        const isActualRow = row.label === 'Actual';
        const allZero = filterData.every((item) => item[col] === 0);
        const shouldCheckActual = allZero && isActualRow;
        const shouldDisable = allZero && !isActualRow;
        const isChecked = shouldCheckActual || row.isChecked[col];

        return (
          <Box
            className={classes.checkBoxCell}
            sx={{
              background: isChecked ? COLORS.WHITE : shouldDisable ? 'default' : 'default',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: 'center' }}>
              {!isColumnUniform || isActualRow
                ? (
                  <Checkbox
                    size="small"
                    checked={shouldCheckActual || row.isChecked[col]}
                    onChange={() => handleCheckboxChange(row._id, col)}
                    disabled={shouldDisable || isColumnUniform}
                  />
                  )
                : null}

              <Box
                className={classes.checkBoxValue}
              >
                {params.value}
              </Box>
            </Box>

            <Box sx={{ flexShrink: 0, width: '50%', display: 'flex', justifyContent: 'center' }}>
              {col === 'rop' && (
                <IconButton
                  className={classes.filterTableMarker}
                >
                  {row?.label === 'Low' && <ArrowDropUp sx={{ fontSize: 50, color: COLORS.GRAY }} />}
                  {row?.label === 'Actual' && <Circle sx={{ fontSize: 15, color: COLORS.GRAY }} />}
                  {row?.label === 'High' && <Square sx={{ fontSize: 15, color: COLORS.GRAY }} />}
                </IconButton>
              )}

              {col === 'rpm' && (
                <Box
                  className={classes.lineDashType}
                  sx={{
                    border: `2px ${FILTER_STYLE[row.label]?.lineDashStyle}`,
                  }}
                />
              )}

              {col === 'flow_in' && (
                <Box
                  className={classes.lineColorFlow_in}
                  sx={{
                    border: `2px solid ${FILTER_STYLE[row.label]?.color}`,
                  }}
                />
              )}

              {col === 'sbp' && (
                <Box
                  sx={{
                    width: 60,
                    border: `${FILTER_STYLE[row.label].lineThickness}px solid ${COLORS.GRAY}`,
                  }}
                />
              )}

            </Box>
          </Box>
        );
      },
    })),
    {
      field: 'reset all',
      headerName: 'Reset',
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
            Reset
          </Typography>
        </Box>
      ),
      width: 78,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        if (params.row.label === 'Actual') {
          return (
            <IconButton
              onClick={() => handleResetAll()}
            >
              <RestoreOutlined />
            </IconButton>
          );
        }
        return null;
      },
    },
  ];

  const handleCheckboxChange = (rowIndex, field) => {
    setFilterData(prevData =>
      prevData.map((row) =>
        row?._id === rowIndex
          ? { ...row, isChecked: { ...row.isChecked, [field]: !row.isChecked[field] } }
          : row
      )
    );
  };

  const generateViewportMinMax = () => {
    const extractAllDepthPointsArrays = (data) => {
      return data.flatMap(item => item.depth_points);
    };

    const allDepthPoints = extractAllDepthPointsArrays(chartData);
    const minMaxData = [...allDepthPoints, ...window.data];
    const keysToCheck = ['esd_with_sbp', 'ecd_with_sbp', 'pp', 'fp'];

    const allValues = [];

    // Collect all values from the specified keys
    keysToCheck.forEach(key => {
      minMaxData.forEach(obj => {
        if (key in obj) {
          allValues.push(obj[key]);
        }
      });
    });

    // Calculate the overall lowest and highest values
    const overallLowest = Math.min(...allValues);
    const overallHighest = Math.max(...allValues);

    const results = {
      lowest: overallLowest,
      highest: overallHighest,
    };

    return results;
  };

  const generateWindowDataPoints = (config) => {
    const extractAllDepthPointsArrays = (data) => {
      return data.flatMap(item => item.depth_points);
    };

    const allDepthPoints = extractAllDepthPointsArrays(chartData);
    const dataPoints = [];

    if (window?.data.length === 1) {
      const singleDataPoint = window?.data[0];
      dataPoints.push(
        { x: singleDataPoint[config.key], y: 0, markerType: 'none' },
        { x: singleDataPoint[config.key], y: singleDataPoint.md },
        { x: singleDataPoint[config.key], y: allDepthPoints.at(-1).md, markerType: 'none' }
      );
    } else if (window?.data.length > 1) {
      const firstPoint = window?.data[0];
      const lastPoint = window?.data.at(-1);

      if (firstPoint.md > 0) {
        dataPoints.push({ x: firstPoint[config.key], y: 0, markerType: 'none' });
      }

      window?.data.forEach(item => {
        dataPoints.push({ x: item[config.key], y: item.md });
      });

      dataPoints.push({ x: lastPoint[config.key], y: allDepthPoints.at(-1).md, markerType: 'none' });
    }

    const formattedData = dataPoints.map(item => {
      const formattedItem = {
        x: parseFloat(Number(item.x).toFixed(window?.units?.md?.decimals)),
        y: parseFloat(Number(item.y).toFixed(window?.units?.pp?.decimals)),
      };
      if (item.markerType) {
        formattedItem.markerType = item.markerType;
      }
      return formattedItem;
    });
    return formattedData;
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [chartData]);

  const CustomLegend = () => {
    const updateHide = (key, hideValue) => {
      handleScroll();
      setChartConfig(prevItems =>
        prevItems.map(item =>
          item.key === key ? { ...item, hide: hideValue } : item
        )
      );
    };

    return (
      <>
        {!sensitivityAnalysis.loading && (
          <Grid container ref={containerRef}
            onScroll={handleScroll} height={'20px'}>
            {chartConfig.map((item) => (
              <Box
                key={item.name}
                className={classes.legendItem}
                sx={{ opacity: item.hide ? 0.4 : 1 }}
                onClick={() => {
                  updateHide(item.key, !item.hide);
                }}
              >
                <LinearScaleOutlined fontSize="large" sx={{ color: item.color, mr: 1 }} />
                {item.name}
              </Box>
            ))}
          </Grid>
        )}
      </>
    );
  };

  const options = useMemo(() => ({
    theme: 'light2',
    animationEnabled: true,
    zoomEnabled: true,
    exportEnabled: true,
    zoomType: 'y',
    toolbar: {
      itemBackgroundColor: '#d3d3d3',
      itemBackgroundColorOnHover: '#3e3e3e',
      buttonBorderColor: '#3e3e3e',
    },
    backgroundColor: '#FFF',
    axisX: {
      viewportMinimum: generateViewportMinMax()?.lowest - 0.1,
      viewportMaximum: generateViewportMinMax()?.highest + 0.1,
      interval: getOptimalInterval(generateViewportMinMax()?.lowest, generateViewportMinMax()?.highest, 10),
      valueFormatString: '0.0',
      labelFontSize: 16,
      gridThickness: 1,
      title: `EMW (${sensitivityAnalysis?.units?.depth_points?.esd?.label})`,
      titleFontSize: 22,
    },
    axisY: {
      viewportMinimum: null,
      viewportMaximum: null,
      title: `MD (${sensitivityAnalysis?.units?.depth_points?.md?.label})`,
      titleFontSize: 22,
      labelFontSize: 16,
      includeZero: false,
      valueFormatString: '0',
      reversed: true,
      labelPlacement: 'outside',
      gridThickness: 1,
    },
    height: 800,
    legend: {
      fontSize: 20,
    },
    data: [
      ...chartConfig.map((config) => {
        if (chartData.length > 0) {
          if (config.hide) return {};
          if (config.key === 'pp' || config.key === 'fp') {
            return {
              type: 'line',
              name: config.name,
              showInLegend: false,
              toolTipContent: config.tooltip,
              dataPoints: generateWindowDataPoints(config),
              color: config.color,
            };
          }
        }
        return {};
      }),
      ...chartData,
    ],
  }), [chartConfig, chartData]);

  const renderRunCell = (params) => {
    const { row } = params;
    const borderStyle = row?.lineDashStyle || 'solid';
    const lineThickness = row?.lineThickness || 2;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography style={{ fontWeight: 800, fontSize: '1rem', color: row?.color }}>
          {row?.runNumber}
        </Typography>
        <IconButton
          className={classes.sensitivityTableMarker}
          sx={{
            '&::after': {
              content: '""',
              position: 'absolute',
              top: '50%',
              left: 0,
              right: 0,
              borderTop: `${lineThickness}px ${borderStyle} ${row?.color}`,
              transform: 'translateY(-50%)',
            },
          }}
        >
          {row?.markerType === 'triangle' && <ArrowDropUp sx={{ fontSize: 50, color: row?.color }} />}
          {row?.markerType === 'circle' && <Circle sx={{ fontSize: 15, color: row?.color }} />}
          {row?.markerType === 'square' && <Square sx={{ fontSize: 15, color: row?.color }} />}
        </IconButton>
      </div>
    );
  };

  useEffect(() => {
    const filteredData = hiddenChartData.filter(item => {
      const row = tableRow.find(row => row.index === item.index);
      return row?.hide;
    });
    const isAllFalseForAnyParam = SENSITIVITY_ANALYSIS_FILTER_PARAMS.some(param => {
      return filterData.every(item => item.isChecked[param] === false);
    });
    if (isAllFalseForAnyParam) {
      setChartData([]);
      return;
    }
    setChartData(filteredData);
  }, [tableRow]);

  const updateHideRunTable = (key, hideValue) => {
    setTableRows(prevItems =>
      prevItems.map(item =>
        item.index === key ? { ...item, hide: !hideValue } : item
      )
    );
  };

  const renderRunHideCell = (params) => {
    return (
      <Box
        className={classes.checkBoxCell}
        sx={{
        }}
      >
        <Checkbox
          size="small"
          checked={params?.row?.hide}
          onClick={() => {
            updateHideRunTable(params?.row?.index, params?.row?.hide);
          }}
        />
      </Box>
    );
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          onCloseClick();
        }}
        maxWidth={'lg'}
        fullWidth={true}
        style={{
          marginRight: '20px'
        }}
        PaperProps={{
          sx: {
            overflow: 'visible'
          },
        }}
        className={classes.dialogModal}
      >
        <div className={classes.dialogTitle}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                {`${wellDetails?.Rig} | ${wellDetails?.Well} | ${rowData?.row?.name}`}
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    onCloseClick();
                  }}
                >
                  <CancelPresentationRounded fontSize='large' />
                </IconButton>
              </Grid>
            </Grid>
          </Typography>
        </div>
        <Divider />
        <Box display="flex" justifyContent="center" margin="1%" alignItems="center">

          {/* Type Filter */}
          <Grid item>
            <FormControl fullWidth sx={{ bgcolor: 'transparent', width: '120px' }}>
              <InputLabel id="filter-input-label">Type</InputLabel>
              <Select
                labelId="filter-select-id"
                id="filter-id"
                value={plotFor}
                label="Filter"
                disabled={sensitivityAnalysis?.loading}
                onChange={(e) => setPlotFor(e.target.value)}
                sx={{
                  fontWeight: 'bolder',
                  fontSize: '18px',
                  background: 'unset'
                }}
              >
                {['ESD', 'ECD'].map((item, idItem) => (
                  <MenuItem
                    key={`item_${item}_${idItem}`}
                    value={item}
                    sx={{ color: item?.color, fontSize: '18px', fontWeight: 'bold' }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Filter Toggle Button */}
          {!sensitivityAnalysis?.loading && (
            <Grid item>
              <Box
                className={classes.sensitivityFilter}
                onClick={() => setShowFilter(!showFilter)}
              >
                {!showFilter && (
                  <IconButton variant="solid" fontSize="large">
                    <FilterAltRounded />
                  </IconButton>
                )}
              </Box>
            </Grid>
          )}
          {/* Filter Table */}
          {showFilter && (
            <Grid item xs={5} sx={{ position: 'relative' }}>
              {/* Datagrid inside a Box to allow absolute positioning */}
              <Box sx={{ position: 'relative' }}>
                <Datagrid
                  fileName={`${wellDetails?.Well?.trim()}-${rowData?.row?.name?.trim()}-Sensitivity`}
                  toolbar={{
                    options: {
                      columns: false,
                      filters: false,
                      density: false,
                      export: {
                        enabled: true,
                      }
                    },
                  }}
                  sx={{
                    '.MuiDataGrid-cell': { fontSize: '1rem', padding: '2px' },
                    '.MuiDataGrid-columnHeader': { fontSize: '1rem', fontWeight: '900 !important' },
                    '& .MuiDataGrid-footerContainer': { display: 'none' },
                    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                      outline: 'none !important',
                    },
                    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
                    {
                      outline: 'none !important',
                    },
                  }}
                  data={filterData}
                  loading={sensitivityAnalysis.loading}
                  columns={columns_table}
                  height={'225px'}
                  disableSelectionOnClick
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  getRowHeight={() => 'auto'}
                  pageSize={100}
                />

                {/* Chevron Left Icon Positioned Over Datagrid on the Right */}
                <IconButton
                  onClick={() => setShowFilter(false)}
                  className={classes.filterTogglerTableBtn}
                >
                  <ChevronLeft sx={{ fontSize: '30px' }} />
                </IconButton>
              </Box>
            </Grid>
          )}
        </Box>
        <Grid container item xs={showTable ? 8 : 12} marginTop={showFilter ? '2%' : '0%'} alignItems="center" justifyContent="center">
          <Grid item>
            <CustomLegend />
          </Grid>
        </Grid>
        <Box style={{
          overflow: 'auto',
        }}>
          <Grid container>
            <Grid item xs={showTable ? 7 : 12} sx={{
              p: 2,
              pt: 4,
            }}>
              {sensitivityAnalysis.loading
                ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ height: '700px' }}
                  >
                    <CircularProgress />
                  </Box>
                  )
                : (
                  <CanvasJSChart
                    options={options}
                  />

                  )}
            </Grid>
            {!showTable && <div className={classes.menuTogglerBtn}>
              <IconButton
                onClick={() => {
                  setShowTable(!showTable);
                }}>
                <ChevronLeft sx={{ fontSize: '33px' }} />
              </IconButton>
            </div>}
            {showTable && <Grid item xs={5} marginTop={'2.5%'}>
              <div className={classes.menuTogglerTableBtn}>
                <IconButton
                  onClick={() => {
                    setShowTable(!showTable);
                  }}>
                  <ChevronRight sx={{ fontSize: '33px' }} />
                </IconButton>
              </div>
              {showTable && <Grid item xs={12}>
                <Datagrid
                  fileName={`${wellDetails?.Well?.trim()}-${rowData?.row?.name?.trim()}-Sensitivity`}
                  loading={sensitivityAnalysis.loading}
                  data={tableRow}
                  sx={{
                    '.MuiDataGrid-cell': {
                      fontSize: '1rem',
                      padding: '2px',
                    },
                    '.MuiDataGrid-columnHeader': {
                      fontSize: '1.2rem',
                      fontWeight: '900 !important',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                      overflow: 'visible',
                      lineHeight: '1.43rem',
                      whiteSpace: 'normal',
                    },
                    '& .MuiDataGrid-footerContainer': {
                      minHeight: '0px'
                    },
                    '& .cellLink': {
                      color: '#0000EE',
                      cursor: 'pointer'
                    },
                    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                      outline: 'none !important',
                    },
                    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
                    {
                      outline: 'none !important',
                    },
                  }}
                  columns={columns(plotFor, sensitivityAnalysis?.units, renderRunCell, renderRunHideCell)}
                  onCellClick={(row) => {
                    if (row.field === 'runNumber' && row?.row?.depth_points?.length) {
                      setShowRunChart({
                        open: true,
                        data: row
                      });
                    }
                  }}
                  disableSelectionOnClick
                  disableStickyHeader={true}
                  autoHeight={false}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  height={'800px'}
                  getRowHeight={() => 'auto'}
                  pageSize={100}
                  toolbar={{
                    options: {
                      columns: false,
                      filters: false,
                      density: false,
                      export: {
                        enabled: true,
                      }
                    },
                  }}
                />
              </Grid>}
            </Grid>}
          </Grid>
        </Box>
      </Dialog>
      {
        showRunChart.open
          ? <SensitivityAnalysisRunChartDialog
            wellDetails={wellDetails}
            data={showRunChart.data}
            rowData={rowData}
            isOpen={showRunChart.open}
            onCloseClick={() => {
              setShowRunChart({
                open: false,
                data: null
              });
            }}
          />
          : null
      }
    </>
  );
};
SensitivityAnalysisChart.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  rowData: PropTypes.any,
  wellDetails: PropTypes.any
};
SensitivityAnalysisChart.defaultProps = {
  selectedJob: {}
};

export default SensitivityAnalysisChart;
