import { ACCESS_TYPES, REDUX_ACTIONS } from '../../shared/config/constants';
import { isMobile } from 'react-device-detect';
import { DEFAULT_SCREEN_VIEW_MODE } from '../../utils/constants';

const currentUser = null;

const initialState = {
  accessType: ACCESS_TYPES.NORMAL,
  loading: false,
  logged: !!currentUser,
  data: currentUser ?? {},
  errorMessage: '',
  newUserId: '',
  newPasswordProvided: false,
  marketPlaceInventoryView: 0,
  preferences: {
    datachartSettings: {
      labelsToHide: [],
      columnVisibilityModel: {},
      columnsColorDefinition: {},
    },
    aspectRatioPage: DEFAULT_SCREEN_VIEW_MODE.aspectRatioPage,
    aspectChanged: DEFAULT_SCREEN_VIEW_MODE.aspectChanged,
    browserTypeMobile: isMobile,
    assetDataId: null,
    filterId: null,
    shoppingCarts: JSON.parse(localStorage.getItem('shoppingCarts')),
    chartViewMode: 0 // 0 - default, 1 - stacked, 2 - speedchart
  },
  ip: null,
  location: {
    latitude: null,
    longitude: null
  },
  profilePicUpload: false,
  fetchEpicorToken: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          datachartSettings: {
            ...state.preferences.datachartSettings,
            ...action.payload,
          },
          aspectRatioPage: action.payload?.aspectRatioPage || state.preferences.aspectRatioPage
        },
      };
    case REDUX_ACTIONS.SET_ASPECT_RATIO_PAGE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          aspectRatioPage: {
            ...action.payload.aspect,
          },
          aspectChanged: action.payload.status,
        },
      };
    case REDUX_ACTIONS.DATA_CHART_PREFERENCES_RESET:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          datachartSettings: initialState.preferences.datachartSettings,
        },
        aspectRatioPage: DEFAULT_SCREEN_VIEW_MODE.aspectRatioPage,
        aspectChanged: DEFAULT_SCREEN_VIEW_MODE.aspectChanged,
      };
    case REDUX_ACTIONS.ACCESS_TYPE:
      return {
        ...state,
        accessType: action.payload.accessType,
        newUserId: action.payload.newUserId,
        newPasswordProvided: action.payload.newPasswordProvided,
        loading: false,
        errorMessage: '',
      };
    case REDUX_ACTIONS.NEW_USER_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.NEW_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        newPasswordProvided: true,
        accessType: ACCESS_TYPES.NORMAL,
        newUserId: '',
      };
    case REDUX_ACTIONS.NEW_USER_PASSWORD_FAILED:
      return {
        ...state,
        newPasswordProvided: false,
        errorMessage: action.payload.message,
        accessType: ACCESS_TYPES.NEW_USER,
      };
    case REDUX_ACTIONS.NEW_USER_PASSWORD_LOADED:
      return {
        ...state,
        loading: false,
      };
    case REDUX_ACTIONS.NEW_USER_PASSWORD_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
      };
    case REDUX_ACTIONS.LOGIN_USER_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
      };
    case REDUX_ACTIONS.LOGIN_USER_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
      };
    case REDUX_ACTIONS.SET_USER:
      return {
        ...state,
        logged: true,
        errorMessage: '',
        ...action.payload,
      };
    case REDUX_ACTIONS.SET_IP_DETAILS:
      return {
        ...state,
        ip: action.payload.ip,
        location: action.payload.location
      };
    case REDUX_ACTIONS.REMOVE_USER:
      return {
        ...initialState,
      };
    case REDUX_ACTIONS.SEND_PASSWORD_RESET_EMAIL_LOADING:
      return {
        ...state,
        loading: true,
        resetEmailSuccess: false,
        resetEmailError: false,
        errorMessage: '',
      };
    case REDUX_ACTIONS.SEND_PASSWORD_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        resetEmailSuccess: true,
        resetEmailError: false,
        errorMessage: '',
      };
    case REDUX_ACTIONS.SEND_PASSWORD_RESET_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
        resetEmailSuccess: false,
        resetEmailError: true,
        errorMessage: action.payload.message,
      };
    case REDUX_ACTIONS.SEND_PASSWORD_RESET_EMAIL_RESET:
      return {
        ...state,
        loading: false,
        resetEmailSuccess: false,
        resetEmailError: false,
        errorMessage: '',
      };
    case REDUX_ACTIONS.RESET_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
        resetPasswordSuccess: false,
        resetPasswordError: false,
        errorMessage: '',
      };
    case REDUX_ACTIONS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        resetPasswordSuccess: true,
        resetPasswordError: false,
        errorMessage: '',
      };
    case REDUX_ACTIONS.RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        resetPasswordSuccess: false,
        resetPasswordError: true,
        errorMessage: action.payload.message,
      };
    case REDUX_ACTIONS.RESET_PASSWORD_RESET:
      return {
        ...state,
        loading: false,
        resetPasswordSuccess: false,
        resetPasswordError: false,
        errorMessage: '',
      };
    case REDUX_ACTIONS.SEND_SIGN_UP_REQUEST_LOADING:
      return {
        ...state,
        loading: true,
        signUpSuccess: false,
        signUpError: false,
        errorMessage: '',
      };
    case REDUX_ACTIONS.SEND_SIGN_UP_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        signUpSuccess: true,
        signUpError: false,
        errorMessage: '',
      };
    case REDUX_ACTIONS.SEND_SIGN_UP_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        signUpSuccess: false,
        signUpError: true,
        errorMessage: action.payload.message,
      };
    case REDUX_ACTIONS.SEND_SIGN_UP_REQUEST_RESET:
      return {
        ...state,
        loading: false,
        signUpSuccess: false,
        signUpError: false,
        errorMessage: '',
      };
    case REDUX_ACTIONS.SET_MARKETPLACE_INVENTORY_VIEW:
      return {
        ...state,
        marketPlaceInventoryView: action.payload.marketPlaceInventoryView,
      };
    case REDUX_ACTIONS.GET_UPDATE_USER_PREFERENCES:
      return {
        ...state,
        preferences: action.resetAspect
          ? {
              ...state.preferences,
              ...action.payload,
              datachartSettings: { ...state.preferences.datachartSettings },
              aspectRatioPage: DEFAULT_SCREEN_VIEW_MODE.aspectRatioPage,
              aspectChanged: DEFAULT_SCREEN_VIEW_MODE.aspectChanged,
            }
          : { ...state.preferences, ...action.payload, datachartSettings: { ...state.preferences.datachartSettings } },
      };
    case REDUX_ACTIONS.SET_SHOPPING_CART_PREFERENCES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          shoppingCarts: {
            ...state.preferences.shoppingCarts,
            ...action.payload,
          },
        },
      };
    case REDUX_ACTIONS.UPDATE_COLUMNS_USER_PREFERENCES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          datachartSettings: { ...action.payload }
        },
      };
    case REDUX_ACTIONS.SET_CHART_VIEW_MODE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          chartViewMode: action.payload,
        },
      };
    case REDUX_ACTIONS.USERS_PROFILE_PICTURE_UPLOAD_SUCCESS:
      return {
        ...state,
        profilePic: action.payload.profilePic,
        profilePicUpload: action.payload.message
      };
    case REDUX_ACTIONS.USERS_PROFILE_PICTURE_UPLOAD_ERROR:
      return {
        ...state,
        profilePicUpload: action.payload.message
      };
    case REDUX_ACTIONS.EPICOR_TOKEN_REFRESH_ERROR:
      return {
        ...state,
        fetchEpicorToken: action.payload,
      };
    case REDUX_ACTIONS.SET_UNIT_SYSTEM_USER_PREFERENCES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          unitSystem: action.payload
        },
      };
    default:
      return state;
  }
};

export default authReducer;
