import { makeStyles } from '@mui/styles';
import { COLORS } from '../../../../shared/config/colors';
const styles = makeStyles(() => ({
  modalTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
    fontSize: '18px',
    cursor: 'pointer'
  },
  dialogModal: {
    '& .MuiPaper-root': {
      marginLeft: '10px',
      '& .makeStyles-dialogTitle-37': {
        padding: '16px 12px !important',
      },
      maxWidth: '2100px'
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '1.3rem',
      fontWeight: 'bold',
    }
  },
  headerCell: {
    border: '2px solid white',
    backgroundColor: '#cccccc',
    color: 'black',
    fontWeight: 'bold',
    padding: '4px',
    height: '20px'
  },
  rowCell: {
    border: '2px solid white',
    backgroundColor: '#e0e0e0',
    color: 'black',
    padding: '4px',
    height: '20px'
  },
  tableContainer: {
    maxWidth: 300,
    height: 'auto',
    maxHeight: 600
  },
  dialogTitle: {
    lineHeight: '2 !important',
    padding: '8px 10px !important',
  },
  menuTogglerBtn: {
    position: 'absolute',
    bottom: '30vh',
    zIndex: 2000,
    right: '-20px',
    backgroundColor: '#e0e0d1',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#c3c3c3',
    },
  },
  menuTogglerTableBtn: {
    position: 'absolute',
    bottom: '30vh',
    zIndex: 2000,
    right: '40.5%',
    backgroundColor: '#e0e0d1',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#c3c3c3',
    },
  },
  filterTogglerTableBtn: {
    position: 'absolute',
    top: '50%',
    right: '-10px',
    transform: 'translateY(-50%)',
    backgroundColor: '#e0e0d1',
    borderRadius: '50%',
    zIndex: 2000,
    '&:hover': { backgroundColor: '#c3c3c3' },
  },
  filterTableHeader: {
    fontSize: '14px',
    padding: '4px',
    textAlign: 'center',
    fontWeight: 900,
    border: '1px solid #D3D3D3',
  },
  filterTableBorder: {
    border: '1px solid #D3D3D3'
  },
  customLegendGrid: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: '300px',
    width: '50%',
    alignItems: 'center',
  },
  checkBoxCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    textAlign: 'center',
    gap: 1,
    flexShrink: 0,
    overflow: 'hidden'
  },
  sensitivityFilter: {
    fontSize: '19px',
    fontWeight: 900,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px'
  },
  sensitivityTableMarker: {
    position: 'relative',
    padding: 0,
    width: 40,
    height: 40,
    marginLeft: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterTableMarker: {
    position: 'relative',
    padding: 0,
    width: 40,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '100%',
      height: 3,
      backgroundColor: COLORS.GRAY,
      transform: 'translateY(-50%)',
    },
  },
  checkBoxValue: {
    whiteSpace: 'nowrap',
    fontWeight: '500',
    fontSize: '16px',
    alignContent: 'center',
    alignItems: 'center'
  },
  lineColorFlow_in: {
    width: 60,
    height: 3,
    marginTop: 0,
    marginBottom: 0,
    alignSelf: 'center'
  },
  lineDashType: {
    width: 60,
    height: 3,
    borderColor: COLORS.GRAY,
    marginTop: 0,
    marginBottom: 0,
    alignSelf: 'center'
  }
}));
export default styles;
