/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Grid, Typography, IconButton } from '@mui/material';
import { CancelPresentationRounded, ContentCopyOutlined, DeleteRounded, DirectionsRunRounded, EditOutlined, SaveRounded, RotateLeftRounded, FileDownload, WavesOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import columns from './table_config';
import styles from './styles';
import ModalDialog from '../ModalDialog';
import Datagrid from '../Datagrid';
import ReactJson from 'react-json-view';
import { addNewHydraulicsRow, copyHydraulicsRow, createHydraulics, deleteHydraulics, downloadRunInputs, fetchHydraulicsDrillstringDrillFluid, listHydraulics, listHydraulicsDetails, listMudDisplacement, listMudPumps, runHydraulics, runSensitivityAnalysis, updateHydraulics } from '../../../store/actions/hydraulics';
import HydraulicsDetailsModal from './HydraulicsRunModalDialog';
import { enqueueSnackbar } from '../../../store/actions/snackbar';
import { REDUX_ACTIONS } from '../../../shared/config/constants';
import SelectEditInputTextField from '../SelectEditInputTextField';
import PumpRampModel from './PumpRampModel';
import DepthVsDensityChartDialog from './DepthVsDensityChartDialog';
import DeleteConfirmationModal from '../../deleteConfirmationModal';
import { listWindow } from '../../../store/actions/wellsWindow';
import { listStrippingData } from '../../../store/actions/hydraulicsStripping';
import useUnsavedChanges from '../../../hooks/useUnsavedChanges';
import useUnitConversion from '../../../hooks/useUnitConversion';
import MudDisplacementModal from './MudDisplacementModal';
import MudDisplacementChart from './MudDisplacementChartDialog';
import ConfirmationModal from '../../confirmationModal';
import SensitivityAnalysisChart from './SensitivityAnalysisChartDialog';
import { HYDRAULICS_CONSTRAIN_CHECKS } from '../../../utils/constants';
const HydraulicsModal = ({
  onCloseClick,
  selectedJob,
  isOpen
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const apiRef = useGridApiRef();
  const hydraulics = useSelector((state) => state.hydraulics);
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});
  const [editDialog, setEditDialog] = useState(false);
  const [editData, setEditData] = useState({});
  const [showPumpRamp, setShowPumpRamp] = useState(false);
  const [pumpRamps, setPumpRamps] = useState([]);
  const [showDepthVsDensity, setShowDepthVsDensity] = useState({
    open: false,
    data: []
  });
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const { unit } = useUnitConversion();
  const [mudDisplacementModal, setMudDisplacementModal] = useState({
    open: false,
    data: null
  });
  const [showMudDisplacementChartModal, setShowMudDisplacementChartModal] = useState({
    open: false,
    data: []
  });
  const {
    unSavedExists,
    handleClose,
    UnsavedChangesModal
  } = useUnsavedChanges(properties, onCloseClick);
  const [showConfirmationModal, setShowConfirmationModal] = useState({
    open: false,
    data: null,
  });
  const [runSentivitiyAnalysis, setRunSentivitiyAnalysis] = useState(false);
  const [showSensitivityAnalysisChartModal, setShowSensitivityAnalysisChartModal] = useState({
    open: false,
    data: []
  });

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchHydraulicsDrillstringDrillFluid(selectedJob?.wellsInfoId));
      dispatch(listStrippingData(selectedJob?.wellsInfoId));
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && (selectedJob?._id || selectedJob?.wellsInfoId)) {
      const query = selectedJob?.fromWells
        ? {
            wellsInfoId: selectedJob?.wellsInfoId
          }
        : {
            jobId: selectedJob?._id
          };
      dispatch(listHydraulics(query));
      dispatch(listWindow(selectedJob?.wellsInfoId));
      dispatch(listMudPumps(selectedJob?.wellsInfoId));
    }
  }, [isOpen]);

  useEffect(() => {
    if (!hydraulics.loading && !hydraulics?.drillDetails?.loading) {
      setProperties(hydraulics?.data);
    }
  }, [hydraulics]);

  useEffect(() => {
    if (runSentivitiyAnalysis) {
      document.body.style.cursor = 'wait';
    } else {
      document.body.style.cursor = 'default';
    }
    return () => {
      document.body.style.cursor = 'default';
    };
  }, [runSentivitiyAnalysis]);

  useEffect(() => {
    if (properties[0]?.name === '') {
      const id = properties[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 0
        });
        apiRef.current.setCellMode(id, 'name', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'name');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties?.length]);

  const closeHandler = () => {
    if (unSavedExists) {
      handleClose(false);
      return;
    }
    setProperties([]);
    onCloseClick();
  };

  const getModalTitle = () => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {`${selectedJob?.Rig || ''} | ${selectedJob?.Well || ''}`}
          </Grid>
          <Grid item>
            <IconButton
              onClick={closeHandler}
            >
              <CancelPresentationRounded fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const addHydraulics = () => {
    dispatch(addNewHydraulicsRow());
  };

  const checkValidation = (row) => {
    const { notes } = row;
    if (notes?.length > 500) return 'Notes should not exceed more than 500 characters';
    return '';
  };

  const handleSave = (row) => {
    if (row?.name) {
      const validationError = checkValidation(row);
      if (validationError === '') {
        setInvalidRows(prev => ({ ...prev, [row._id]: false }));
        if (row?.isNewRow) {
          delete row?.isNewRow;
          dispatch(createHydraulics(row, selectedJob?._id, selectedJob.wellsInfoId, false, selectedJob?.fromWells));
        } else {
          dispatch(updateHydraulics({ ...row }, selectedJob?.fromWells));
        }
        setTimeout(() => {
          apiRef.current.setCellMode(row._id, 'name', 'view');
          Object.keys(row).forEach((field) => {
            if (field !== '_id' && field !== 'name') {
              apiRef.current.setCellMode(row._id, field, 'view');
            }
          });
        }, 100);
      } else {
        if (validationError.length) setInvalidRows(prev => ({ ...prev, [row._id]: true }));
        dispatch(enqueueSnackbar(validationError, 'error', new Date().getTime() + Math.random()));
      }
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          'Please enter the name',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const removeHydraulics = (row) => {
    if (row?.isNewRow) {
      const updatedHydraulics = properties.filter(item => item._id !== row?._id);
      setProperties(updatedHydraulics);
      const query = selectedJob?.fromWells
        ? {
            wellsInfoId: selectedJob?.wellsInfoId
          }
        : {
            jobId: selectedJob?._id
          };
      dispatch(listHydraulics(query));
    } else {
      const query = selectedJob?.fromWells
        ? {
            wellsInfoId: selectedJob?.wellsInfoId
          }
        : {
            jobId: selectedJob?._id
          };
      dispatch(deleteHydraulics(row?._id, query));
    }
    setDeleteAction(false);
    setDeleteRow({});
  };

  const runHydraulicsProxy = (hydraulicsParams) => {
    const body = {
      query: selectedJob?.fromWells
        ? {
            wellsInfoId: selectedJob?.wellsInfoId
          }
        : {
            jobId: selectedJob?._id
          },
      runId: hydraulicsParams?._id
    };
    dispatch(runHydraulics(body));
  };

  const runHydraulicsSensitivityAnalysis = () => {
    const body = {
      query: selectedJob?.fromWells
        ? {
            wellsInfoId: selectedJob?.wellsInfoId
          }
        : {
            jobId: selectedJob?._id
          },
      runId: showConfirmationModal.data?._id
    };
    setRunSentivitiyAnalysis(true);
    dispatch(runSensitivityAnalysis(body, () => {
      setShowConfirmationModal({
        open: false,
        data: null
      });
      setRunSentivitiyAnalysis(false);
    }));
  };

  const RenderJsonField = ({ value }) => {
    const data = useRef(value.run_details);
    return (<>
      <ReactJson
        src={data.current}
        name={false}
        sortKeys={true}
        collapsed={true}
        displayDataTypes={false}
        quotesOnKeys={false}
      />
      <IconButton
        variant="solid"
        disabled={hydraulics?.loading || value?.isNewRow || hydraulics.downloadModel === 'loading'}
        onClick={() => {
          const payload = {
            fileName: `${selectedJob?.Rig?.trim()}_${selectedJob?.Well?.trim()}_${value.name?.trim()}_inputs`,
            runsId: value._id,
            wellsInfoId: value.wellsInfoId
          };
          dispatch(downloadRunInputs(payload));
        }}
      >
        <FileDownload />
      </IconButton>
    </>
    );
  };

  const renderSensitivityAction = ({ row }) => {
    return (
      <Grid container justifyContent="center">
        {row?.isSensitivityResults && <IconButton>
          <WavesOutlined />
        </IconButton>}
      </Grid>
    );
  };

  const showError = (message) => {
    dispatch(enqueueSnackbar(message, 'error'));
    return false;
  };

  const checkSensitivityConstraints = (row) => {
    const { flow_in, trip_data } = row?.run_details || {};

    if (!flow_in) {
      return showError(HYDRAULICS_CONSTRAIN_CHECKS.sensitivity_flow_rate);
    }

    if (trip_data && Object.keys(trip_data).length > 0) {
      return showError(HYDRAULICS_CONSTRAIN_CHECKS.sensitivity_stripping);
    }
    setShowConfirmationModal({ open: true, data: row });
  };

  const renderActionCell = ({ row }) => {
    const { run_details } = row;
    const { drillStrings = [], drillFluids = [] } = hydraulics?.drillDetails?.hydraulicsDrillDetails || {};
    const checkForDeletedDrillStringId = drillStrings.some(drillString => drillString._id === run_details?.drillStringsId);
    const checkForDeletedDrillFluidId = drillFluids.some(drillString => drillString._id === run_details?.drillFluidsId);
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          disabled={hydraulics?.loading || !row?.name || row?.isNewRow}
          onClick={() => {
            if (!row?.isNewRow) {
              dispatch(listHydraulicsDetails(row?._id));
            } else {
              delete row?.isNewRow;
              dispatch(createHydraulics(row, selectedJob?._id, selectedJob.wellsInfoId, true, selectedJob?.fromWells));
              dispatch({
                type: REDUX_ACTIONS.HYDRAULICS_DETAILS_LIST_SUCCESS,
                payload: {
                  data: [],
                  unModifiedData: [],
                  paginatedElements: 0,
                  units: hydraulics?.units
                },
              });
            }
            setEditData(row);
            setEditDialog(true);
          }}
        >
          <EditOutlined />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={!row.isDirty || hydraulics?.loading}
          onClick={() => {
            handleSave(row);
          }}
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={row?.isNewRow}
          onClick={() => {
            const filteredRow = hydraulics.unModifiedData.filter((item) => item._id === row._id)[0];
            dispatch(copyHydraulicsRow(filteredRow));
          }}
        >
          <ContentCopyOutlined />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={hydraulics?.loading}
          onClick={() => {
            setDeleteAction(true);
            setDeleteRow(row);
          }}
        >
          <DeleteRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={hydraulics?.loading || row?.isNewRow || !row?.run_details || Object.keys(row?.run_details).length === 0 || !checkForDeletedDrillFluidId || !checkForDeletedDrillStringId}
          onClick={() => {
            runHydraulicsProxy(row);
          }}
        >
          <DirectionsRunRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={hydraulics?.loading || row?.isNewRow || !row?.run_details || Object.keys(row?.run_details).length === 0 || !checkForDeletedDrillFluidId}
          onClick={() => {
            setMudDisplacementModal({
              open: true,
              data: row
            });
            const body = {
              runId: row?._id,
              wellsInfoId: row?.wellsInfoId
            };
            dispatch(listMudDisplacement(body));
          }}
        >
          <RotateLeftRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={hydraulics?.loading || !row?.name || row?.isNewRow}
          onClick={() => checkSensitivityConstraints(row)}
        >
          <WavesOutlined />
        </IconButton>
      </Grid>
    );
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          const isDirty = value !== item[field];
          return {
            ...item,
            [field]: value,
            isDirty
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };
  const renderValueCell = (params) => {
    return <SelectEditInputTextField {...params} updateData={updateData} />;
  };
  if (apiRef.current === null) apiRef.current = {};
  return (
    <>
      <ModalDialog
        title={getModalTitle()}
        isOpen={isOpen}
        onClose={() => {
          setProperties([]);
          onCloseClick();
        }}
        dialogClass={classes.dialogModal}
        maxWidth={'lg'}
      >
        <Grid container>
          <Grid item className={classes.customTool} xs={1} justifyContent="space-between" >
            <Grid item>
              <Typography variant='button' fontWeight='700' onClick={addHydraulics} className={hydraulics.loading || properties[0]?.isNewRow ? classes.disableIcon : ''}>+ Add</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Datagrid
              apiRef={apiRef}
              data={properties}
              sx={{
                marginLeft: '-6px',
                '.MuiDataGrid-cell': {
                  fontSize: '1rem',
                  fontWeight: 900,
                  textAlign: 'justify'
                },
                '.MuiDataGrid-columnHeader': {
                  fontSize: '1.2rem',
                  fontWeight: '900 !important'
                },
                '& .cellLink': {
                  color: '#0000EE',
                  cursor: 'pointer'
                }
              }}
              loading={hydraulics.loading || hydraulics?.drillDetails?.loading}
              columns={columns(RenderJsonField, renderActionCell, renderValueCell, hydraulics?.units, unit, renderSensitivityAction)}
              onRowClick={(params, evt) => {
                evt.preventDefault();
                evt.stopPropagation();
              }}
              onCellClick={(row) => {
                if (row.field === 'connection_sbp' && row?.row?.run_response?.pump_ramps?.length) {
                  setShowPumpRamp(true);
                  setPumpRamps(row);
                }
                if (row.field === 'bh_esd' || row.field === 'bh_ecd') {
                  if (row.row?.run_response?.depth_points.length) {
                    setShowDepthVsDensity({
                      open: true,
                      data: row.row
                    });
                  }
                }
                if (row.field === 'total_strokes') {
                  setShowMudDisplacementChartModal({
                    open: true,
                    data: row
                  });
                }
                if (row.field === 'sensitivity' && row?.row?.isSensitivityResults) {
                  setShowSensitivityAnalysisChartModal({
                    open: true,
                    data: row
                  });
                }
              }}
              disableStickyHeader={false}
              autoHeight={false}
              getRowId={(row) => row._id}
              getRowHeight={() => 'auto'}
              height={'50vh'}
              getRowClassName={(params) => {
                return invalidRows[params.id] ? classes.invalidRow : '';
              }}
              pageSize={100}
              editMode="cell"
              toolbar={{
                options: {
                  columns: false,
                  filters: false,
                  density: false,
                  export: false
                },
              }}
            />
          </Grid>
        </Grid>
      </ModalDialog>
      {
        deleteAction && <DeleteConfirmationModal
          open={deleteAction}
          handleDelete={removeHydraulics}
          handleClose={setDeleteAction}
          deleteRow={deleteRow}
        />
      }
      <UnsavedChangesModal />
      {editDialog && editData?.name
        ? (
          <HydraulicsDetailsModal
            selectedJob={selectedJob}
            isOpen={editDialog}
            onCloseClick={() => {
              setEditDialog(false);
              setEditData({});
              dispatch({
                type: REDUX_ACTIONS.HYDRAULICS_SET_ID,
                payload: {
                  data: '',
                },
              });
            }}
            data={editData}
          />
          )
        : null}
      {
        showPumpRamp
          ? <PumpRampModel
            wellDetails={selectedJob}
            pumpRamps={pumpRamps}
            isOpen={showPumpRamp}
            pumpRampUnits={hydraulics?.units?.run_response?.pump_ramps}
            onCloseClick={() => {
              setShowPumpRamp(false);
            }}
          />
          : null
      }
      {showDepthVsDensity.open && (
        <DepthVsDensityChartDialog
          isOpen={showDepthVsDensity.open}
          onCloseClick={() => {
            setShowDepthVsDensity({
              open: false,
              data: []
            });
          }}
          wellDetails={selectedJob}
          data={showDepthVsDensity.data}
        />
      )}
      {
        mudDisplacementModal.open
          ? <MudDisplacementModal
            selectedJob={selectedJob}
            rowData={mudDisplacementModal.data}
            isOpen={mudDisplacementModal.open}
            onCloseClick={() => {
              setMudDisplacementModal(
                {
                  open: false,
                  data: {}
                }
              );
            }}

          />
          : null

      }
      {
        showMudDisplacementChartModal.open
          ? <MudDisplacementChart
            wellDetails={selectedJob}
            rowData={showMudDisplacementChartModal.data}
            isOpen={showMudDisplacementChartModal.open}
            onCloseClick={() => {
              setShowMudDisplacementChartModal({
                open: false,
                data: []
              });
            }}
          />
          : null
      }
      {
        showConfirmationModal.open && <ConfirmationModal
          confirmAction={runSentivitiyAnalysis}
          open={showConfirmationModal.open}
          rowData={showConfirmationModal.data}
          handleAction={() => {
            runHydraulicsSensitivityAnalysis();
          }}
          handleClose={() => {
            setShowConfirmationModal({
              open: false,
              data: null
            });
          }}
          modalLabel={'Proceed with Sensitivity Analysis?'}
        />
      }
      {
        showSensitivityAnalysisChartModal.open
          ? <SensitivityAnalysisChart
            wellDetails={selectedJob}
            rowData={showSensitivityAnalysisChartModal.data}
            isOpen={showSensitivityAnalysisChartModal.open}
            onCloseClick={() => {
              setShowSensitivityAnalysisChartModal({
                open: false,
                data: []
              });
            }}
          />
          : null
      }
    </>
  );
};
HydraulicsModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};
HydraulicsModal.defaultProps = {
  selectedJob: {}
};
export default HydraulicsModal;
