/* eslint-disable react/prop-types */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './styles';
import FileUploadDialog from '../ui/FileUploadDialog';
import { useState } from 'react';
import { importWellsJsonFile, listWells } from '../../store/actions/wells';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../store/actions/snackbar';
import { CancelPresentationRounded } from '@mui/icons-material';

const WellsImportConfirmationModal = ({ open, handleClose, importRow }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [uploadOpen, setUploadOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadSuccess = () => {
    handleClose(false);
    dispatch(
      enqueueSnackbar(
        'Well Data Uploaded Successfully',
        'success',
        new Date().getTime() + Math.random()
      )
    );
    dispatch(listWells());
  };

  const handleUploadJson = (selectedFile) => {
    dispatch(importWellsJsonFile(selectedFile, importRow?.wellsInfoId, setIsLoading, handleUploadSuccess));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose(false);
        }}
        fullWidth={true}
        className={classes.dialogModal}
      >
        <div className={classes.dialogTitle}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>Attention!</Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    handleClose(false);
                  }}
                >
                  <CancelPresentationRounded fontSize='large' />
                </IconButton>
              </Grid>
            </Grid>
          </Typography>
        </div>
        <Divider />
        <DialogContent>
          <DialogContentText align='center' style={{ marginLeft: '-0.7rem' }} color={'black'} fontSize={'18px'} fontWeight='bold' id="alert-dialog-description">
          Existing well data will be lost
          </DialogContentText>
        </DialogContent>
        <Grid container item justifyContent='center' marginBottom='2rem'>
          <DialogActions>
            <Button
              style={{ backgroundColor: '#444', width: '100px' }}
              onClick={() => {
                setUploadOpen(true);
              }}
            >
              Continue
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
      <FileUploadDialog
          open={uploadOpen}
          handleClose={() => {
            setUploadOpen(false);
            handleClose(false);
          }}
          handleUpload={handleUploadJson}
          type={'json'}
          isLoading = {isLoading}
        />
    </>
  );
};

WellsImportConfirmationModal.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  importRow: PropTypes.any
};

export default WellsImportConfirmationModal;
